//Create Debate Constants
export const CREATE_DEBATE_REQUEST = 'CREATE_DEBATE_REQUEST';
export const CREATE_DEBATE_SUCCESS = 'CREATE_DEBATE_SUCCESS';
export const CREATE_DEBATE_FAIL = 'CREATE_DEBATE_FAIL';

//List Debate Constants
export const DEBATE_LIST_REQUEST = 'DEBATE_LIST_REQUEST';
export const DEBATE_LIST_SUCCESS = 'DEBATE_LIST_SUCCESS';
export const DEBATE_LIST_FAIL = 'DEBATE_LIST_FAIL';

//Register Debate Constants
export const DEBATE_REGISTER_SUCCESS = 'DEBATE_REGISTER_SUCCESS';
export const DEBATE_REGISTER_FAIL = 'DEBATE_REGISTER_FAIL';

//Unregister Debate Constants
export const DEBATE_UNREGISTER_SUCCESS = 'DEBATE_UNREGISTER_SUCCESS';
export const DEBATE_UNREGISTER_FAIL = 'DEBATE_UNREGISTER_FAIL';

//Fetch Scheduled Debates Constants
export const FETCH_DEBATE_REQUEST = 'FETCH_DEBATE_REQUEST';
export const FETCH_DEBATE_SUCCESS = 'FETCH_DEBATE_SUCCESS';
export const FETCH_DEBATE_FAIL = 'FETCH_DEBATE_FAIL';

//Delete Debate Constants
export const DEBATE_DELETE_SUCCESS = 'DEBATE_DELETE_SUCCESS';
export const DEBATE_DELETE_FAIL = 'DEBATE_DELETE_FAIL';

//Register Judge Constants
export const REGISTER_JUDGE_REQUEST = 'REGISTER_JUDGE_REQUEST';
export const REGISTER_JUDGE_SUCCESS = 'REGISTER_JUDGE_SUCCESS';
export const REGISTER_JUDGE_FAIL = 'REGISTER_JUDGE_FAIL';

//Unregister Judge Constants
export const UNREGISTER_JUDGE_REQUEST = 'UNREGISTER_JUDGE_REQUEST';
export const UNREGISTER_JUDGE_SUCCESS = 'UNREGISTER_JUDGE_SUCCESS';
export const UNREGISTER_JUDGE_FAIL = 'UNREGISTER_JUDGE_FAIL';

//Assign and ready roles
export const ASSIGN_ROLE_SUCCESS = 'ASSIGN_ROLE_SUCCESS';
export const ASSIGN_ROLE_FAIL = 'ASSIGN_ROLE_FAIL';
export const READY_ROLE_SUCCESS = 'READY_ROLE_SUCCESS';
export const READY_ROLE_FAIL = 'READY_ROLE_FAIL';
export const UNASSIGN_ROLE_SUCCESS = 'UNASSIGN_ROLE_SUCCESS';
export const UNASSIGN_ROLE_FAIL = 'UNASSIGN_ROLE_FAIL';
export const UNREADY_ROLE_SUCCESS = 'UNREADY_ROLE_SUCCESS';
export const UNREADY_ROLE_FAIL = 'UNREADY_ROLE_FAIL';

//Reset Debate
export const RESET_SELECTED_DEBATE = 'RESET_SELECTED_DEBATE';

//Vote for debate
export const VOTE_DEBATE_SUCCESS = 'VOTE_DEBATE_SUCCESS';
export const VOTE_DEBATE_FAIL = 'VOTE_DEBATE_FAIL';
export const VOTE_DEBATE_REQUEST = 'VOTE_DEBATE_REQUEST';

//Set debate to completed
export const COMPLETE_DEBATE_SUCCESS = 'COMPLETE_DEBATE_SUCCESS';
export const COMPLETE_DEBATE_FAIL = 'COMPLETE_DEBATE_FAIL';
export const COMPLETE_DEBATE_REQUEST = 'COMPLETE_DEBATE_REQUEST';

//Fetch debate format
export const ADD_TRANSCRIPT_REQUEST = 'ADD_TRANSCRIPT_REQUEST';
export const ADD_TRANSCRIPT_SUCCESS = 'ADD_TRANSCRIPT_SUCCESS';
export const ADD_TRANSCRIPT_FAIL = 'ADD_TRANSCRIPT_FAIL';

//Add Flow
export const ADD_FLOW_REQUEST = 'ADD_FLOW_REQUEST';
export const ADD_FLOW_SUCCESS = 'ADD_FLOW_SUCCESS';
export const ADD_FLOW_FAIL = 'ADD_FLOW_FAIL';

//Fetch article
export const FETCH_ARTICLE_REQUEST = 'FETCH_ARTICLE_REQUEST';
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_FAIL = 'FETCH_ARTICLE_FAIL';
