
import React from "react";
import ReactDOM from "react-dom/client";
import window from "./setup.js";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import HomepageScreen from './screens/HomepageScreen.js';
import LoginScreen from './screens/LoginScreen.js';
import SignupScreen from './screens/SignupScreen.js';
import CreateDebateScreen from './screens/CreateDebateScreen.js';
import MyDebatesScreen from './screens/MyDebatesScreen.js';
import ProfileScreen from './screens/ProfileScreen.js';
import ActiveDebateScreen from './screens/ActiveDebateScreen.js';
import ScheduledDebatesScreen from './screens/ScheduledDebatesScreen.js';
import MessageDevScreen from './screens/MessageDevScreen.js';
import ArticleScreen from './screens/ArticleScreen.js';
import './styles/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SocketLayout from './SocketLayout'; // Import the SocketLayout
import { ThemeProvider } from '@mui/material/styles';
import { MaterialTheme } from './MaterialTheme.js';
import LearnScreen from './screens/LearnScreen.js';

// Routes for the application
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomepageScreen />} />
      <Route path="/sign-in" element={<LoginScreen />} />
      <Route path="/sign-up" element={<SignupScreen />} />
      <Route path="/create-debate" element={<CreateDebateScreen />} />
      <Route path="/my-debates" element={<MyDebatesScreen />} />
      <Route path="/profile" element={<ProfileScreen />} />
      <Route path="/messageDev" element={<MessageDevScreen />} />
      <Route path="/learn" element={<LearnScreen />} />
      <Route path="/learn/:articleUnit" element={<ArticleScreen />}></Route>
      <Route
        path="/learn/:articleUnit/:articleRoute"
        element={<ArticleScreen />}
      />
      <Route
        path="/active-debate/:debateId"
        element={
          <SocketLayout>
            <ActiveDebateScreen />
          </SocketLayout>
        }
      />
      <Route path="/scheduled-debates" element={<ScheduledDebatesScreen />} />
      <Route path="/message-dev" element={<MessageDevScreen />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={MaterialTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
