import React from "react";
import { Container } from "react-bootstrap";

const DebateDetails = ({ event, dateTime, side, creatorName }) => {
  return (
    <Container>
      <p>Event: {event}</p>
      <p>Date and Time: {new Date(dateTime).toLocaleString()}</p>
      <p>
        {side === "Random"
          ? "Sides will be selected randomly"
          : `${creatorName} is ${side}`}
      </p>
    </Container>
  );
};

export default DebateDetails;
