import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Typography,
  Box,
  Collapse,
  useMediaQuery,
  useTheme,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Add as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const getColumnHeaders = (event, flowType, team1) => {
  switch (event) {
    case "Policy":
      return flowType === "Aff"
        ? ["1AC", "1NC", "2AC", "2NC / 1NR", "1AR", "2NR", "2AR"]
        : ["1NC", "2AC", "2NC/ 1NR", "1AR", "2NR", "2AR"];
    case "Lincoln-Douglas":
      return flowType === "Aff"
        ? ["AC", "NC", "1AR", "NR", "2AR"]
        : ["NC", "1AR", "NR", "2AR"];
    case "Public Forum":
      if (team1 === "Affirmative") {
        return flowType === "Aff"
          ? ["AC", "NR", "AS", "NS", "AFF", "NFF"]
          : ["NC", "AR", "NR", "AS", "NS", "AFF", "NFF"];
      } else {
        return flowType === "Aff"
          ? ["AC", "NR", "AR", "NS", "AS", "NFF", "AFF"]
          : ["NC", "AR", "NS", "AS", "NFF", "AFF"];
      }
    default:
      return [];
  }
};

const isAffirmativeColumn = (header) => {
  return ["AC", "AR", "1AR", "2AR", "AFF", "AS", "1AC", "2AC"].includes(header);
};

const UserFlowGrid = forwardRef(
  ({ debateStarted, event, isSubmitted }, ref) => {
    const { roles } = useSelector((state) => state.socket);
    const whoFirst = () => {
      if (roles.aff) {
        return roles.aff === "Team A" ? "Affirmative" : "Negative";
      }
    };
    const team1 = whoFirst();
    const createInitialGrid = (flowType) => {
      const headers = getColumnHeaders(event, flowType, team1);
      return [
        [{ content: "", height: 0 }].concat(
          Array(headers.length - 1).fill(null)
        ),
        Array(headers.length).fill(null),
      ];
    };

    const [gridData, setGridData] = useState([
      { type: "Aff", data: createInitialGrid("Aff") },
      { type: "Neg", data: createInitialGrid("Neg") },
    ]);
    const [currentPage, setCurrentPage] = useState(0);
    const [expanded, setExpanded] = useState(true);
    const [activeCell, setActiveCell] = useState({ row: 0, col: 0 });
    const cellRefs = useRef({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [flowToDelete, setFlowToDelete] = useState(null);

    const handleDeleteFlow = (index) => {
      setFlowToDelete(index);
      setOpenDeleteDialog(true);
    };

    const confirmDeleteFlow = () => {
      if (flowToDelete !== null) {
        const newGridData = gridData.filter(
          (_, index) => index !== flowToDelete
        );
        setGridData(newGridData);
        setCurrentPage(Math.min(currentPage, newGridData.length - 1));
        setFlowToDelete(null);
      }
      setOpenDeleteDialog(false);
    };

    const cancelDeleteFlow = () => {
      setFlowToDelete(null);
      setOpenDeleteDialog(false);
    };

    const getFlowData = () => {
      return gridData.map((flow) => {
        const tableName = `${flow.type} Flow`;
        const columnHeaders = getColumnHeaders(event, flow.type, team1);

        const tableData = {};
        columnHeaders.forEach((header, colIndex) => {
          tableData[header] = flow.data.map((row) => {
            const cell = row[colIndex];
            return cell ? cell.content : null;
          });
        });

        return {
          [tableName]: {
            headers: columnHeaders,
            data: tableData,
          },
        };
      });
    };

    useImperativeHandle(ref, () => ({
      getFlowData,
    }));

    useEffect(() => {
      updateCellHeights();
    }, [gridData]);

    useEffect(() => {
      if (activeCell.row !== -1 && activeCell.col !== -1) {
        const cellRef = cellRefs.current[`${activeCell.row}-${activeCell.col}`];
        if (cellRef) {
          cellRef.focus();
        }
      }
    }, [activeCell]);

    const updateCellHeights = () => {
      const newGridData = JSON.parse(JSON.stringify(gridData));
      newGridData[currentPage].data.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
          if (cell !== null) {
            const cellRef = cellRefs.current[`${rowIndex}-${colIndex}`];
            if (cellRef) {
              const height = cellRef.clientHeight;
              newGridData[currentPage].data[rowIndex][colIndex].height = height;
            }
          }
        });
      });
      setGridData(newGridData);
    };

    const handleCellChange = (rowIndex, colIndex, value) => {
      const newGridData = JSON.parse(JSON.stringify(gridData));
      newGridData[currentPage].data[rowIndex][colIndex].content = value;
      setGridData(newGridData);
    };

    const addCell = (rowIndex, colIndex) => {
      const newGridData = JSON.parse(JSON.stringify(gridData));
      newGridData[currentPage].data[rowIndex][colIndex] = {
        content: "",
        height: 0,
      };

      // Ensure there's always one more row
      if (rowIndex === newGridData[currentPage].data.length - 1) {
        newGridData[currentPage].data.push(
          Array(
            getColumnHeaders(event, newGridData[currentPage].type).length
          ).fill(null)
        );
      }

      setGridData(newGridData);
      setActiveCell({ row: rowIndex, col: colIndex });
    };

    const getRowHeight = (row) => {
      return Math.max(...row.map((cell) => cell?.height || 0), 40); // Minimum height of 40px
    };

    const isCellAddable = (rowIndex, colIndex) => {
      if (rowIndex === 0 && colIndex === 0) return false; // First cell is not addable
      if (colIndex === 0)
        return gridData[currentPage].data[rowIndex - 1][0] !== null;
      return gridData[currentPage].data[rowIndex][colIndex - 1] !== null;
    };

    const handleKeyDown = (e, rowIndex, colIndex) => {
      if (e.key === "Enter") {
        e.preventDefault();
        const nextRowIndex = rowIndex + 1;
        if (gridData[currentPage].data[nextRowIndex]?.[colIndex] === null) {
          addCell(nextRowIndex, colIndex);
        } else {
          setActiveCell({ row: nextRowIndex, col: colIndex });
        }
      } else if (e.key === "Tab") {
        e.preventDefault();
        const columnHeaders = getColumnHeaders(
          event,
          gridData[currentPage].type
        );
        const nextColIndex = colIndex + 1;
        if (nextColIndex < columnHeaders.length) {
          if (gridData[currentPage].data[rowIndex][nextColIndex] === null) {
            addCell(rowIndex, nextColIndex);
          } else {
            setActiveCell({ row: rowIndex, col: nextColIndex });
          }
        } else if (rowIndex < gridData[currentPage].data.length - 1) {
          // Move to the next row if we're at the last column
          setActiveCell({ row: rowIndex + 1, col: 0 });
        }
      }
    };

    const renderCell = (rowIndex, colIndex, cell) => {
      if (cell === null && isCellAddable(rowIndex, colIndex) && !isSubmitted) {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <IconButton
              size="small"
              onClick={() => addCell(rowIndex, colIndex)}
              sx={{ opacity: 0.5 }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      } else if (cell !== null) {
        return (
          <TextField
            value={cell.content}
            onChange={(e) =>
              handleCellChange(rowIndex, colIndex, e.target.value)
            }
            onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
            variant="outlined"
            size="small"
            fullWidth
            multiline
            InputProps={{
              inputRef: (el) =>
                (cellRefs.current[`${rowIndex}-${colIndex}`] = el),
              readOnly: isSubmitted,
            }}
            autoFocus={
              activeCell.row === rowIndex && activeCell.col === colIndex
            }
          />
        );
      }
      return null;
    };

    const addNewFlow = (type) => {
      const newGridData = [...gridData];
      const flowCount = newGridData.filter((grid) => grid.type === type).length;
      newGridData.push({ type, data: createInitialGrid(type) });
      setGridData(newGridData);
      setCurrentPage(newGridData.length - 1);
    };

    if (!debateStarted) return null;

    const columnHeaders = getColumnHeaders(event, gridData[currentPage].type);

    return (
      <Box sx={{ p: 2 }}>
        <Button
          onClick={() => setExpanded(!expanded)}
          startIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          sx={{ mb: 2 }}
        >
          {expanded ? "Collapse Flow" : "Expand Flow"}
        </Button>
        <Collapse in={expanded}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography variant="h5" sx={{ flexGrow: 1 }}>
              {gridData[currentPage].type} Flow{" "}
              {Math.floor(currentPage / 2) + 1}
            </Typography>
            {isSubmitted && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                This flow has been submitted and is now read-only. You can view
                it on My Debates page.
              </Typography>
            )}
            {!isSubmitted && (
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleDeleteFlow(currentPage)}
                sx={{ ml: 2 }}
              >
                Delete Flow
              </Button>
            )}
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden", mb: 4 }}>
            <TableContainer
              sx={{
                maxHeight: "70vh",
                maxWidth: "100%",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {columnHeaders.map((header, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          minWidth: isMobile ? 100 : 150,
                          backgroundColor: isAffirmativeColumn(header)
                            ? "lightblue"
                            : "lightsalmon",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gridData[currentPage].data.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      style={{ height: getRowHeight(row) }}
                    >
                      {row.map((cell, colIndex) => (
                        <TableCell
                          key={colIndex}
                          padding="none"
                          sx={{
                            verticalAlign: "top",
                            position: "relative",
                            backgroundColor: isAffirmativeColumn(
                              columnHeaders[colIndex]
                            )
                              ? "aliceblue"
                              : "mistyrose",
                          }}
                        >
                          {renderCell(rowIndex, colIndex, cell)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {!isSubmitted && (
            <Box sx={{ mb: 2 }}>
              <Button onClick={() => addNewFlow("Aff")} sx={{ mr: 1, mb: 1 }}>
                Add Aff Flow
              </Button>
              <Button onClick={() => addNewFlow("Neg")} sx={{ mb: 1 }}>
                Add Neg Flow
              </Button>
            </Box>
          )}
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {gridData.map((grid, index) => (
              <Button
                key={index}
                variant={index === currentPage ? "contained" : "outlined"}
                onClick={() => setCurrentPage(index)}
                sx={{ mr: 1, mb: 1 }}
              >
                {grid.type} Flow {Math.floor(index / 2) + 1}
              </Button>
            ))}
          </Box>
        </Collapse>
        <Dialog
          open={openDeleteDialog}
          onClose={cancelDeleteFlow}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Flow?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this flow? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDeleteFlow}>Cancel</Button>
            <Button onClick={confirmDeleteFlow} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
);

export default UserFlowGrid;
