import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  ListItem,
  List,
} from "@mui/material";
import Unit from "../components/Unit";
import unitRegistry from "../static/unitRegistry";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getArticleStates } from "../redux/actions/articleStatesActions";

const LearnScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getArticleStates());
  }, [dispatch]);

  return (
    <Container maxWidth="md">
      <Box sx={{ pl: 2 }}>
        <Typography variant="h4" gutterBottom>
          Learn
        </Typography>
        <Typography variant="subtitle">What do you want to learn?</Typography>
      </Box>
      <List sx={{ width: "100%" }}>
        {unitRegistry.map((item, index) => (
          <ListItem key={index}>
            <Card variant="outlined" sx={{ width: "100%" }}>
              <Unit unitRoute={item.route}></Unit>
            </Card>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default LearnScreen;
