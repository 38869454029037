import {
  INITIATE_COIN_FLIP,
  COIN_FLIP_RESULT,
  SIDE_ORDER_SELECTED,
  ROLES_FINALIZED,
  SET_DECISION_MAKER,
  SET_STAGE,
} from "../constants/coinFlipConstants";

export const initiateCoinFlip = (debateId, selection) => ({
  type: INITIATE_COIN_FLIP,
  payload: { debateId, selection },
});

export const coinFlipResult = (result, coinTossWinner, decisionMaker) => ({
  type: COIN_FLIP_RESULT,
  payload: { result, coinTossWinner, decisionMaker },
});

export const selectSideOrder = (selection, nextDecisionMaker) => ({
  type: SIDE_ORDER_SELECTED,
  payload: { selection, decisionMaker: nextDecisionMaker },
});

export const finalizeCoinFlip = (roles) => ({
  type: ROLES_FINALIZED,
  payload: roles,
});

export const setDecisionMaker = (nextDecisionMaker) => (dispatch) => {
  dispatch({
    type: SET_DECISION_MAKER,
    payload: nextDecisionMaker,
  });
};

export const setStage = (stage) => (dispatch) => {
  dispatch({
    type: SET_STAGE,
    payload: stage,
  });
};
