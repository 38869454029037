import { createTheme } from "@mui/material/styles";
export const MaterialTheme = createTheme({
  palette: {
    primary: {
      main: "#255bbd", // Blue
    },
    secondary: {
      main: "#FFA500", // Orange
    },
    white: {
      main: "#FFFFFF",
    },
  },
});
