import React, { useEffect, useRef, useState } from "react";
import { sendAudioForTranscription } from "../utils/audioTranscriptionUtils";

const AudioRecorder = ({
  status,
  speechIndex,
  onTranscriptionComplete,
  recordingAllowed,
}) => {
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [transcription, setTranscription] = useState("");
  const [recordingState, setRecordingState] = useState("inactive");
  const streamRef = useRef(null);

  useEffect(() => {
    if (status === "active" && recordingAllowed) {
      startRecording();
    } else if (status === "paused") {
      pauseRecording();
    } else if (status === "ended") {
      stopRecordingAndTranscribe();
    } else if (status === "inactive") {
      resetRecordingState();
    }
  }, [status, recordingAllowed]);

  useEffect(() => {
    return () => {
      cleanupSpeechRecording();
    };
  }, []);

  const startRecording = async () => {
    if (recordingState === "inactive" && recordingAllowed) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        streamRef.current = stream;
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorder.onstart = () => {
          setRecordingState("recording");
        };

        mediaRecorder.start();
      } catch (error) {
        console.error("Error starting recording:", error);
      }
    } else if (recordingState === "paused" && recordingAllowed) {
      mediaRecorderRef.current.resume();
      setRecordingState("recording");
    }
  };

  const pauseRecording = () => {
    if (recordingState === "recording" && mediaRecorderRef.current) {
      mediaRecorderRef.current.pause();
      setRecordingState("paused");
    }
  };

  const stopRecordingAndTranscribe = () => {
    if (mediaRecorderRef.current && recordingState !== "inactive") {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = () => {
        console.log("MediaRecorder stopped");
        setRecordingState("inactive");
        const finalAudioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        handleTranscription(finalAudioBlob);
      };

      // Stop all tracks on the stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }

      mediaRecorderRef.current = null;
    }
  };

  const handleTranscription = async (audioBlob) => {
    try {
      console.log("Sending audio for transcription...");
      const result = await sendAudioForTranscription(audioBlob);
      setTranscription(result);
      console.log("Transcription complete:", result);
      onTranscriptionComplete(result, speechIndex);
    } catch (error) {
      console.error("Error during transcription:", error);
    }
  };

  const resetRecordingState = () => {
    setRecordingState("inactive");
    setTranscription("");
    audioChunksRef.current = [];
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const cleanupSpeechRecording = () => {
    if (mediaRecorderRef.current && recordingState !== "inactive") {
      mediaRecorderRef.current.stop();
    }
    resetRecordingState();
  };

  return (
    <div>
      {recordingState !== "inactive" && (
        <div>
          Recording in progress...{" "}
          {recordingState === "paused" ? "(Paused)" : "(Active)"}
        </div>
      )}
    </div>
  );
};

export default React.memo(AudioRecorder);
