import { useCallback, useRef, useState } from "react";
import Peer from "simple-peer/simplepeer.min.js";

export const useWebRTC = (
  debateId,
  userInfo,
  iceServers,
  socket,
  localStreamRef,
  peersRef,
  setRemoteStreams,
  setConnectionStates,
  initializeLocalStream,
  audioRefs,
  isTURNReady,
  signalQueue,
  setSignalQueue
) => {
  const [useWebRTCError, setUseWebRTCError] = useState(null);

  // Create a new peer
  const createPeer = useCallback(
    (userId, stream, initiator = true) => {
      console.log(`Creating peer for user: ${userId}`);
      const peer = new Peer({
        initiator,
        stream,
        trickle: true,
        config: {
          iceServers: [
            {
              urls: iceServers?.urls,
              username: iceServers?.username,
              credential: iceServers?.credential,
            },
          ],
        },
      });

      peer.on("signal", (signalData) => {
        console.log(`Signaling to ${userId}`);
        socket.emit("signal", {
          to: userId,
          from: userInfo._id,
          signal: signalData,
          debateId,
        });
      });

      peer.on("stream", (remoteStream) => {
        console.log(`Received stream from ${userId}`);
        setRemoteStreams((prevStreams) => ({
          ...prevStreams,
          [userId]: remoteStream,
        }));
      });

      peer.on("connect", () => {
        console.log(`Connected to ${userId}`);
        setConnectionStates((prevStates) => ({
          ...prevStates,
          [userId]: "connected",
        }));
      });

      peer.on("close", () => {
        console.log(`Disconnected from ${userId}`);
        setConnectionStates((prevStates) => ({
          ...prevStates,
          [userId]: "disconnected",
        }));
      });

      peer.on("error", (err) => {
        console.error(`Peer error with ${userId}:`, err);
        setUseWebRTCError(
          `Connection error with peer ${userId}. Please try refreshing the page.`
        );
      });

      return peer;
    },
    [iceServers, socket, userInfo._id, setRemoteStreams, setConnectionStates]
  );

  // Handle a new user joining the debate
  const handleUserJoined = useCallback(
    async (data) => {
      if (data.userId !== userInfo._id) {
        try {
          const stream = await initializeLocalStream();
          const peer = createPeer(data.userId, stream, true);
          peersRef.current[data.userId] = peer;
        } catch (error) {
          console.error("Error handling new user:", error);
          setUseWebRTCError(
            `Failed to connect with new user ${data.userId}. Please try refreshing the page.`
          );
        }
      }
    },
    [userInfo._id, initializeLocalStream, createPeer]
  );

  // Handle incoming signals
  const handleSignal = useCallback(
    async ({ from, signal }) => {
      console.log("hi from handleSignal");
      if (!isTURNReady) {
        console.log("ICE servers not ready yet, queuing signal", {
          from,
          signal,
        });
        setSignalQueue((prev) => [...prev, { from, signal }]);
        return;
      }

      try {
        const stream = await initializeLocalStream();
        if (!peersRef.current[from]) {
          const peer = createPeer(from, stream, false);
          peersRef.current[from] = peer;
        }
        peersRef.current[from].signal(signal);
      } catch (error) {
        console.error(`Error handling signal from ${from}:`, error);
        setUseWebRTCError(
          `Failed to process signal from ${from}. Please try refreshing the page.`
        );
      }
    },
    [createPeer, initializeLocalStream, isTURNReady, setSignalQueue]
  );

  // Handle reconnection attempts
  const handleReconnection = useCallback(
    async (userId) => {
      try {
        if (peersRef.current[userId]) {
          peersRef.current[userId].destroy();
        }
        const stream = await initializeLocalStream();
        const newPeer = createPeer(userId, stream, true);
        peersRef.current[userId] = newPeer;
      } catch (error) {
        console.error(`Reconnection failed for user: ${userId}`, error);
        setUseWebRTCError(`Failed to reconnect with user ${userId}.`);
      }
    },
    [createPeer, initializeLocalStream]
  );

  return {
    initializeLocalStream,
    handleUserJoined,
    handleSignal,
    createPeer,
    handleReconnection,
    useWebRTCError,
  };
};
