import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  "&:hover": {
    backgroundColor: "#d3d3d3",
  },
}));

const SignInButton = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/sign-in");
  };

  return (
    <StyledButton {...props} onClick={handleClick}>
      {props.children}
    </StyledButton>
  );
};

export default SignInButton;
