import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setDecisionMaker } from "../redux/actions/coinFlipActions";
import { SocketContext } from "../context/socket";
import { Button } from "react-bootstrap";

const CoinFlip = ({ debateId, user, startDebateHandler }) => {
  const userId = user._id;
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const { result, coinTossWinner, sideOrderSelection, decisionMaker, stage } =
    useSelector((state) => state.coinFlip);
  const { roles, participants } = useSelector((state) => state.socket);
  const { selectedDebate } = useSelector((state) => state.debates);

  useEffect(() => {
    // Set the initial decision maker to the creator
    dispatch(setDecisionMaker(selectedDebate.creator));
  }, [dispatch, selectedDebate.creator]);

  const handleCoinFlip = (choice) => {
    const opp1 = getOpposingFirstSpeaker(); // Get the first speaker of the opposing team
    const creator = getCreator();
    socket.emit("coinFlip", { debateId, selection: choice, creator, opp1 });
  };

  const handleSelectSideOrder = (selection) => {
    // Switch decision maker to the other participant after first selection
    const nextDecisionMaker =
      decisionMaker.userId === selectedDebate.creator.userId
        ? getOpposingFirstSpeaker()
        : getCreator();

    socket.emit("selectSideOrder", {
      debateId,
      selection,
      decisionMaker: nextDecisionMaker,
    });
  };

  const handleFinalizeRoles = (selection) => {
    const roles = getRolesFromSelection(selection);
    socket.emit("finalizeRoles", { debateId, roles, event: "Public Forum" });
  };

  const getCreatorTeam = () => {
    // Determine the creator's team based on their role
    const creatorRole = participants.find(
      (participant) => participant.userId === selectedDebate.creator.userId
    )?.role;
    return creatorRole && (creatorRole.includes("teamA") ? "A" : "B");
  };

  const getOpposingFirstSpeaker = () => {
    // Get the first speaker of the opposing team
    const creatorTeam = getCreatorTeam();
    const opposingTeam = creatorTeam === "A" ? "B" : "A";
    return participants.find(
      (participant) => participant.role === `team${opposingTeam}1`
    );
  };

  const getCreator = () => {
    return participants.find(
      (participant) => participant.userId === selectedDebate.creator.userId
    );
  };

  const getRolesFromSelection = (secondSelection) => {
    const creatorTeam = getCreatorTeam();
    const opposingTeam = creatorTeam === "A" ? "B" : "A";

    const initRoles = {
      Affirmative: null,
      Negative: null,
      "Team A Speaker 1": null,
      "Team A Speaker 2": null,
      "Team B Speaker 1": null,
      "Team B Speaker 2": null,
    };

    const firstSelection = sideOrderSelection; // The first selection made

    if (coinTossWinner.userId === selectedDebate.creator.userId) {
      if (firstSelection === "Aff" || firstSelection === "Neg") {
        // Creator chose side, opponent chooses speaking order
        initRoles.aff =
          firstSelection === "Aff"
            ? `Team ${creatorTeam}`
            : `Team ${opposingTeam}`;
        initRoles.neg =
          firstSelection === "Aff"
            ? `Team ${opposingTeam}`
            : `Team ${creatorTeam}`;

        if (secondSelection === "1st Speaker") {
          initRoles["Team A Speaker 1"] = participants.find(
            (p) => p.role === `team${opposingTeam}1`
          );
          initRoles["Team A Speaker 2"] = participants.find(
            (p) => p.role === `team${opposingTeam}2`
          );
          initRoles["Team B Speaker 1"] = participants.find(
            (p) => p.role === `team${creatorTeam}1`
          );
          initRoles["Team B Speaker 2"] = participants.find(
            (p) => p.role === `team${creatorTeam}2`
          );
        } else {
          initRoles["Team A Speaker 1"] = participants.find(
            (p) => p.role === `team${creatorTeam}1`
          );
          initRoles["Team A Speaker 2"] = participants.find(
            (p) => p.role === `team${creatorTeam}2`
          );
          initRoles["Team B Speaker 1"] = participants.find(
            (p) => p.role === `team${opposingTeam}1`
          );
          initRoles["Team B Speaker 2"] = participants.find(
            (p) => p.role === `team${opposingTeam}2`
          );
        }
      } else {
        // Creator chose speaking order, opponent chooses side
        if (firstSelection === "1st Speaker") {
          initRoles["Team A Speaker 1"] = participants.find(
            (p) => p.role === `team${creatorTeam}1`
          );
          initRoles["Team A Speaker 2"] = participants.find(
            (p) => p.role === `team${creatorTeam}2`
          );
          initRoles["Team B Speaker 1"] = participants.find(
            (p) => p.role === `team${opposingTeam}1`
          );
          initRoles["Team B Speaker 2"] = participants.find(
            (p) => p.role === `team${opposingTeam}2`
          );
        } else {
          initRoles["Team A Speaker 1"] = participants.find(
            (p) => p.role === `team${opposingTeam}1`
          );
          initRoles["Team A Speaker 2"] = participants.find(
            (p) => p.role === `team${opposingTeam}2`
          );
          initRoles["Team B Speaker 1"] = participants.find(
            (p) => p.role === `team${creatorTeam}1`
          );
          initRoles["Team B Speaker 2"] = participants.find(
            (p) => p.role === `team${creatorTeam}2`
          );
        }

        initRoles.aff =
          secondSelection === "Aff"
            ? `Team ${opposingTeam}`
            : `Team ${creatorTeam}`;
        initRoles.neg =
          secondSelection === "Aff"
            ? `Team ${creatorTeam}`
            : `Team ${opposingTeam}`;
      }
    } else {
      if (firstSelection === "Aff" || firstSelection === "Neg") {
        // Opponent chose side, creator chooses speaking order
        initRoles.aff =
          firstSelection === "Aff"
            ? `Team ${opposingTeam}`
            : `Team ${creatorTeam}`;
        initRoles.neg =
          firstSelection === "Aff"
            ? `Team ${creatorTeam}`
            : `Team ${opposingTeam}`;

        if (secondSelection === "1st Speaker") {
          initRoles["Team A Speaker 1"] = participants.find(
            (p) => p.role === `team${creatorTeam}1`
          );
          initRoles["Team A Speaker 2"] = participants.find(
            (p) => p.role === `team${creatorTeam}2`
          );
          initRoles["Team B Speaker 1"] = participants.find(
            (p) => p.role === `team${opposingTeam}1`
          );
          initRoles["Team B Speaker 2"] = participants.find(
            (p) => p.role === `team${opposingTeam}2`
          );
        } else {
          initRoles["Team A Speaker 1"] = participants.find(
            (p) => p.role === `team${opposingTeam}1`
          );
          initRoles["Team A Speaker 2"] = participants.find(
            (p) => p.role === `team${opposingTeam}2`
          );
          initRoles["Team B Speaker 1"] = participants.find(
            (p) => p.role === `team${creatorTeam}1`
          );
          initRoles["Team B Speaker 2"] = participants.find(
            (p) => p.role === `team${creatorTeam}2`
          );
        }
      } else {
        // Opponent chose speaking order, creator chooses side
        if (firstSelection === "1st Speaker") {
          initRoles["Team A Speaker 1"] = participants.find(
            (p) => p.role === `team${opposingTeam}1`
          );
          initRoles["Team A Speaker 2"] = participants.find(
            (p) => p.role === `team${opposingTeam}2`
          );
          initRoles["Team B Speaker 1"] = participants.find(
            (p) => p.role === `team${creatorTeam}1`
          );
          initRoles["Team B Speaker 2"] = participants.find(
            (p) => p.role === `team${creatorTeam}2`
          );
        } else {
          initRoles["Team A Speaker 1"] = participants.find(
            (p) => p.role === `team${creatorTeam}1`
          );
          initRoles["Team A Speaker 2"] = participants.find(
            (p) => p.role === `team${creatorTeam}2`
          );
          initRoles["Team B Speaker 1"] = participants.find(
            (p) => p.role === `team${opposingTeam}1`
          );
          initRoles["Team B Speaker 2"] = participants.find(
            (p) => p.role === `team${opposingTeam}2`
          );
        }

        initRoles.aff =
          secondSelection === "Aff"
            ? `Team ${creatorTeam}`
            : `Team ${opposingTeam}`;
        initRoles.neg =
          secondSelection === "Aff"
            ? `Team ${opposingTeam}`
            : `Team ${creatorTeam}`;
      }
    }

    return initRoles;
  };

  const isCreator = selectedDebate.creator.userId === userId;

  return (
    <div>
      {/* Stage 0: Coin Flip */}
      {stage === 0 && (
        <div>
          {isCreator ? (
            <>
              <h4>Select Heads or Tails</h4>
              <button onClick={() => handleCoinFlip("Heads")}>Heads</button>
              <button onClick={() => handleCoinFlip("Tails")}>Tails</button>
            </>
          ) : (
            <p>
              Waiting for {selectedDebate.creator.name} to select Heads or
              Tails...
            </p>
          )}
        </div>
      )}

      {/* Stage 1: Result Announced */}
      {stage === 1 && (
        <div>
          <p>
            {coinTossWinner.name} won the coin toss with {result}!{" "}
          </p>
          {decisionMaker.userId === userId ? (
            <div>
              <h4>Select side or Speaking Order</h4>
              <button onClick={() => handleSelectSideOrder("Aff")}>Aff</button>
              <button onClick={() => handleSelectSideOrder("Neg")}>Neg</button>
              <button onClick={() => handleSelectSideOrder("1st Speaker")}>
                1st Speaker
              </button>
              <button onClick={() => handleSelectSideOrder("2nd Speaker")}>
                2nd Speaker
              </button>
            </div>
          ) : (
            <p>
              Waiting for{" "}
              {decisionMaker.userId === selectedDebate.creator.userId
                ? selectedDebate.creator.name
                : getOpposingFirstSpeaker()?.name}{" "}
              to select side or speaker order...
            </p>
          )}
        </div>
      )}

      {/* Stage 2: Side/Order Selected */}
      {stage === 2 && (
        <div>
          <p>
            {coinTossWinner.userId === userId
              ? `${selectedDebate.creator.name} chose ${sideOrderSelection}`
              : `${
                  getOpposingFirstSpeaker()?.name
                } chose ${sideOrderSelection}`}
          </p>
          {decisionMaker.userId === userId ? (
            <div>
              <h4>Select remaining option</h4>
              {sideOrderSelection.includes("Speaker") ? (
                <>
                  <button
                    onClick={() =>
                      handleFinalizeRoles(
                        sideOrderSelection === "1st Speaker"
                          ? "Aff"
                          : "1st Speaker"
                      )
                    }
                  >
                    Aff
                  </button>
                  <button
                    onClick={() =>
                      handleFinalizeRoles(
                        sideOrderSelection === "1st Speaker"
                          ? "Neg"
                          : "2nd Speaker"
                      )
                    }
                  >
                    Neg
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() =>
                      handleFinalizeRoles(
                        sideOrderSelection === "Aff" ? "1st Speaker" : "Aff"
                      )
                    }
                  >
                    1st Speaker
                  </button>
                  <button
                    onClick={() =>
                      handleFinalizeRoles(
                        sideOrderSelection === "Aff" ? "2nd Speaker" : "Neg"
                      )
                    }
                  >
                    2nd Speaker
                  </button>
                </>
              )}
            </div>
          ) : (
            <p>Waiting for the opponent to select the remaining option...</p>
          )}
        </div>
      )}

      {/* Stage 3: Roles Finalized */}
      {stage === 3 && (
        <div>
          <p>
            {roles["Team A Speaker 1"]?.name} and{" "}
            {roles["Team A Speaker 2"]?.name} are Speaking first as{" "}
            {roles.aff === "Team A" ? "Aff" : "Neg"}
          </p>
          <p>
            {roles["Team B Speaker 1"]?.name} and{" "}
            {roles["Team B Speaker 2"]?.name} are Speaking second as{" "}
            {roles.neg === "Team B" ? "Neg" : "Aff"}
          </p>
          <Button onClick={startDebateHandler}>Start Debate</Button>
        </div>
      )}
    </div>
  );
};

export default CoinFlip;
