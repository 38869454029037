import React from "react";
import FormattedTimer from "./FormattedTimer";

const TimerDisplay = ({ timeLeft, teamPrepTimes, isJudge, prepTimeActive }) => {
  return (
    <div>
      <FormattedTimer timeLeft={timeLeft} />
      {isJudge && prepTimeActive && <h3>Prep Time Running...</h3>}
      {!isJudge && (
        <>
          <div>Your Prep</div>
          <FormattedTimer timeLeft={teamPrepTimes.team} />
          <div>Opponent Prep</div>
          <FormattedTimer timeLeft={teamPrepTimes.opponent} />
        </>
      )}
    </div>
  );
};

export default TimerDisplay;
