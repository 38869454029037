import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
} from "@mui/material";

const isAffirmativeColumn = (header) => {
  return ["AC", "AR", "1AR", "2AR", "AFF", "AS"].includes(header);
};

const ReadFlowGrid = ({ selectedDebate, userId, event }) => {
  const [currentPage, setCurrentPage] = useState(0);

  // Find the user's flow data
  const userFlow = selectedDebate.flows.find((flow) => flow.userId === userId);

  if (!userFlow) {
    return <Typography>No flow data found for this user.</Typography>;
  }

  const flowData = userFlow.flowData;

  const renderFlow = (flow) => {
    const tableName = Object.keys(flow)[0];
    const { headers, data } = flow[tableName];

    return (
      <Paper sx={{ width: "100%", overflow: "hidden", mb: 4 }}>
        <Typography variant="h6" sx={{ p: 2 }}>
          {tableName}
        </Typography>
        <TableContainer sx={{ maxHeight: "70vh" }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{
                      backgroundColor: isAffirmativeColumn(header)
                        ? "lightblue"
                        : "lightsalmon",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data[headers[0]].map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <TableCell
                      key={colIndex}
                      sx={{
                        backgroundColor: isAffirmativeColumn(header)
                          ? "aliceblue"
                          : "mistyrose",
                      }}
                    >
                      {data[header][rowIndex] || ""}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Debate Flows
      </Typography>
      {renderFlow(flowData[currentPage])}
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}>
        {flowData.map((flow, index) => (
          <Button
            key={index}
            variant={index === currentPage ? "contained" : "outlined"}
            onClick={() => setCurrentPage(index)}
            sx={{ mr: 1, mb: 1 }}
          >
            {Object.keys(flow)[0]}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default ReadFlowGrid;
