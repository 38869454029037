import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import { Box } from '@mui/material';

const App = () => {
  return (
    <div>
      <Header />
      <Box component="main" sx={{ flexGrow: 1, mt: 8 }} className="py-3">
        <Container>
          <Outlet />
        </Container>
      </Box>
    </div>
  );
};

export default App;
