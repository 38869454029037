import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SignInButton from "../components/buttons/SignInButton";
import SignUpButton from "../components/buttons/SignUpButton";
import { CheckCircle } from "@mui/icons-material";
import "../styles/HomepageScreen.css";
import { useSelector } from "react-redux";
import notebook from "../assets/FD-cartoon-notebook.png";
import friends from "../assets/FD-friends.png";
import smiley from "../assets/FD-smiley.png";
import JoinDebateButton from "../components/buttons/JoinDebateButton";
import CreateDebateButton from "../components/buttons/CreateDebateButton";

const HomepageScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <div className="homepage-screen">
      <Container maxWidth="false">
        <Box className="hero-section" textAlign="center">
          <Typography variant="h2" className="hero-title">
            Welcome to Flow Debate
          </Typography>
          <Typography variant="h5" className="hero-subtitle">
            Engage in meaningful online debates and hone your skills
          </Typography>
          {userInfo ? (
            <Box mt={4}>
              <CreateDebateButton variant="contained" className="cta-button">
                Create a Debate
              </CreateDebateButton>
              <JoinDebateButton variant="contained" className="cta-button">
                Join a Debate
              </JoinDebateButton>{" "}
            </Box>
          ) : (
            <Box mt={4}>
              <SignUpButton variant="contained" className="cta-button">
                Sign Up
              </SignUpButton>
              <SignInButton variant="contained" className="cta-button">
                Sign In
              </SignInButton>
            </Box>
          )}
        </Box>

        <Box className="why-debate-section" pt={2}>
          <Typography variant="h4" className="section-title" textAlign="center">
            Why Debate?
          </Typography>
          <Grid
            container
            spacing={4}
            className="section-content"
            mt={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={5}>
              <Box className="why-debate-image" ml={1} />
            </Grid>
            <Grid item xs={12} md={7}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Cultivates critical thinking"
                    primaryTypographyProps={{ fontSize: "1.4em" }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Enhances communication skills"
                    primaryTypographyProps={{ fontSize: "1.4em" }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Builds confidence"
                    primaryTypographyProps={{ fontSize: "1.4em" }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Prepares you for success in all areas of life"
                    primaryTypographyProps={{ fontSize: "1.4em" }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>

        <Box className="why-flow-debate-section" py={5}>
          <Typography variant="h4" className="section-title" textAlign="center">
            Why Flow Debate?
          </Typography>
          <Grid container spacing={4} className="features" mt={4}>
            <Grid item xs={12} md={4}>
              <Card className="feature-card">
                <CardMedia
                  component="img"
                  height="300"
                  image={notebook}
                  alt="Practice Makes Perfect"
                />
                <CardContent>
                  <Typography variant="h6" className="feature-title">
                    Practice Makes Perfect
                  </Typography>
                  <Typography className="feature-description">
                    Hone your skills by participating in practice debates.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className="feature-card">
                <CardMedia
                  component="img"
                  height="300"
                  image={friends}
                  alt="Meet Other Debaters"
                />
                <CardContent>
                  <Typography variant="h6" className="feature-title">
                    Meet Other Debaters
                  </Typography>
                  <Typography className="feature-description">
                    Hear arguments from outside your local circuit.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className="feature-card">
                <CardMedia
                  component="img"
                  height="300"
                  image={smiley}
                  alt="Have Fun"
                />
                <CardContent>
                  <Typography variant="h6" className="feature-title">
                    Have Fun
                  </Typography>
                  <Typography className="feature-description">
                    Enjoy the thrill of a good debate or explore new events.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box className="footer" py={3} textAlign="center">
        <Typography variant="body2">
          © 2024 Flow Debate. All rights reserved.
        </Typography>
      </Box>
    </div>
  );
};

export default HomepageScreen;
