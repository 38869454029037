import { useCallback } from "react";

export const usePreptimeAudio = (
  participants,
  prepTimeActive,
  debateStarted,
  userInfo,
  remoteStreams,
  audioRefs
) => {
  const getTeammateIds = useCallback(() => {
    if (!debateStarted || !participants.length) return [];
    const userRole = participants.find(
      (participant) => participant.userId === userInfo._id
    )?.role;
    if (!userRole) {
      console.log("User role not found");
      return [];
    }
    const teammateIds = participants
      .filter(
        (participant) =>
          participant.role && participant.role.startsWith(userRole.slice(0, -1))
      )
      .map((participant) => participant.userId);
    console.log("Teammate IDs:", teammateIds);
    return teammateIds;
  }, [participants, debateStarted, userInfo._id]);

  const shouldHearUser = useCallback(
    (userId) => {
      if (!prepTimeActive) {
        return true;
      }
      const teammateIds = getTeammateIds();
      return teammateIds.includes(userId);
    },
    [prepTimeActive, getTeammateIds]
  );

  const setPrepAudio = useCallback(() => {
    console.log("prepTimeActive:", prepTimeActive);
    const teammateIds = getTeammateIds();
    console.log("teammateIds: ", teammateIds);

    // Handle remote audio
    Object.entries(remoteStreams).forEach(([userId, stream]) => {
      const audioElement = audioRefs.current[userId];
      if (audioElement) {
        if (!shouldHearUser(userId)) {
          audioElement.muted = true;
        } else {
          audioElement.muted = false;
        }
        console.log(
          `Audio Element set to ${
            audioElement.muted ? "muted" : "unmuted"
          } for ${userId}`
        );
      }
    });
  }, [shouldHearUser, audioRefs]);

  return { setPrepAudio };
};
