import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  registerForDebate,
  unregisterFromDebate,
  registerAsJudge,
  unregisterAsJudge,
  deleteDebate,
  listDebates,
} from "../redux/actions/debateActions";

const useDebateActions = (setShowDeleteModal) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegister = async (debateId) => {
    try {
      await dispatch(registerForDebate(debateId));
      toast.success("Successfully registered for the debate");
      dispatch(listDebates()); // Re-fetch the list of debates
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error registering for the debate"
      );
    }
  };

  const handleUnregister = async (debateId) => {
    try {
      await dispatch(unregisterFromDebate(debateId));
      toast.success("Successfully unregistered from the debate");
      dispatch(listDebates()); // Re-fetch the list of debates
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error unregistering from the debate"
      );
    }
  };

  const handleRegisterAsJudge = async (debateId) => {
    try {
      await dispatch(registerAsJudge(debateId));
      toast.success("Successfully registered as a judge for the debate");
      dispatch(listDebates()); // Re-fetch the list of debates
    } catch (error) {
      console.error("Register judge error:", error.response?.data?.message);
      toast.error(
        error.response?.data?.message || "Error registering as a judge"
      );
    }
  };

  const handleUnregisterAsJudge = async (debateId) => {
    try {
      await dispatch(unregisterAsJudge(debateId));
      toast.success("Successfully unregistered from the debate");
      dispatch(listDebates()); // Re-fetch the list of debates
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error unregistering from the debate"
      );
    }
  };

  const handleDelete = async (debateId) => {
    try {
      await dispatch(deleteDebate(debateId));
      toast.success("Debate cancelled successfully");
      setShowDeleteModal(false); // Close the delete modal
      dispatch(listDebates()); // Re-fetch the list of debates
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error cancelling the debate"
      );
    }
  };

  return {
    handleRegister,
    handleUnregister,
    handleRegisterAsJudge,
    handleUnregisterAsJudge,
    handleDelete,
    navigate,
  };
};

export default useDebateActions;
