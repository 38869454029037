import React, { useEffect, useState, useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SocketContext } from "../context/socket";
import {
  completeDebate,
  addTranscript,
  fetchDebateById,
  addFlow,
} from "../redux/actions/debateActions";
import { startPrepTime, endPrepTime } from "../redux/actions/socketActions";
import TimerDisplay from "./TimerDisplay";
import TimerControls from "./TimerControls";
import AudioRecorder from "./AudioRecorder";
import { Typography } from "@mui/material";
import { current } from "@reduxjs/toolkit";

const SpeechTimer = ({
  debateId,
  userId,
  roles,
  event,
  phases,
  totalPrep,
  isJudge,
  userName,
  getFlowData,
}) => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const { currentSpeechIndex, prepTimeActive, participants } = useSelector(
    (state) => state.socket
  );
  const { selectedDebate } = useSelector((state) => state.debates);

  const [currentSpeech, setCurrentSpeech] = useState(
    phases[currentSpeechIndex]
  );
  const [timeLeft, setTimeLeft] = useState(phases[0].duration || 0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [teamPrepTimes, setTeamPrepTimes] = useState({
    team: totalPrep,
    opponent: totalPrep,
  });
  const [prepTimerRunning, setPrepTimerRunning] = useState({
    team: false,
    opponent: false,
  });
  const [isFlowSubmitted, setIsFlowSubmitted] = useState(false);

  const [isController, setIsController] = useState(false);
  const [isActiveSpeaker, setIsActiveSpeaker] = useState(false);
  const [isMultipleSpeakers, setIsMultipleSpeakers] = useState(false);
  const [recorderStatus, setRecorderStatus] = useState("inactive");
  const [recordingAllowed, setRecordingAllowed] = useState(false);

  useEffect(() => {
    if (socket) {
      socket.on("updateTimer", handleTimer);
      socket.on("debateCompleted", handleFinalCheck);
      return () => {
        socket.off("updateTimer", handleTimer);
        socket.off("debateCompleted", handleFinalCheck);
      };
    }
  }, [socket]);

  useEffect(() => {
    let timer;
    if (timerRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [timerRunning, timeLeft]);

  useEffect(() => {
    let prepTimer;
    if (prepTimerRunning.team || prepTimerRunning.opponent) {
      const activeTeam = prepTimerRunning.team ? "team" : "opponent";
      prepTimer = setInterval(() => {
        setTeamPrepTimes((prevTimes) => ({
          ...prevTimes,
          [activeTeam]: Math.max(prevTimes[activeTeam] - 1, 0),
        }));
      }, 1000);
    }
    return () => clearInterval(prepTimer);
  }, [prepTimerRunning]);

  useEffect(() => {
    checkIfController();
    //setTimeLeft(currentSpeech?.duration);
    if (currentSpeech?.activeSpeaker?.length < 2) {
      handleStartPrepTimer(getTeam());
    }
  }, [currentSpeech]);

  const getUserRole = () => {
    if (!isJudge) {
      const userRole = Object.keys(roles).find(
        (key) => roles[key]?.userId === userId
      );
      console.log("User role:", userRole);
      return userRole;
    }
  };

  const checkIfController = () => {
    if (!isJudge) {
      const userRole = getUserRole();
      if (currentSpeech && currentSpeech.controllers) {
        setIsController(currentSpeech.controllers.includes(userRole));
        setIsActiveSpeaker(currentSpeech?.activeSpeaker.includes(userRole));
      }
    }
  };

  useEffect(() => {
    setCurrentSpeech(phases[currentSpeechIndex]);
    setTimeLeft(phases[currentSpeechIndex]?.duration || 0);
    setTimerRunning(false);
    setRecorderStatus("inactive");
    setRecordingAllowed(false); // Reset recording permission on speech change

    // Check if there are multiple active speakers
    const multipleSpeakers =
      phases[currentSpeechIndex]?.activeSpeaker.length > 1;
    setIsMultipleSpeakers(multipleSpeakers);
  }, [currentSpeechIndex]);

  useEffect(() => {
    if (
      currentSpeechIndex >= phases.length &&
      !selectedDebate.completed &&
      selectedDebate.allTranscriptsLoaded
    ) {
      dispatch(completeDebate(debateId));
      setTimeout(() => {
        socket.emit("completeDebate", { debateId });
      }, 500);
    }
  }, [currentSpeechIndex, selectedDebate.allTranscriptsLoaded]);

  const getTeam = () => {
    if (!isJudge) {
      const controllers = phases[currentSpeechIndex].controllers;
      const userRole = getUserRole();

      if (event !== "Public Forum") {
        if (
          (userRole.includes("Aff") &&
            controllers.some((controller) => controller.includes("Aff"))) ||
          (userRole.includes("Neg") &&
            controllers.some((controller) => controller.includes("Neg")))
        ) {
          return "team";
        } else {
          return "opponent";
        }
      } else if (event === "Public Forum") {
        if (
          (userRole.includes("Team A") &&
            controllers.some((controller) => controller.includes("Team A"))) ||
          (userRole.includes("Team B") &&
            controllers.some((controller) => controller.includes("Team B")))
        ) {
          return "team";
        } else {
          return "opponent";
        }
      }
    }
  };

  const handleStartPrepTimer = (team) => {
    if (currentSpeechIndex !== 0) {
      setPrepTimerRunning((prevTimers) => ({
        ...prevTimers,
        [team]: true,
      }));
      dispatch(startPrepTime());
      socket.emit("startPrepTime", { debateId });
    }
  };

  const handleStopPrepTimer = () => {
    setPrepTimerRunning({
      team: false,
      opponent: false,
    });
    dispatch(endPrepTime());
    socket.emit("endPrepTime", { debateId });
  };

  const handleTimer = useCallback(() => {
    setTimerRunning((prevTimerRunning) => {
      const newTimerRunning = !prevTimerRunning;
      setRecordingAllowed(newTimerRunning);
      setRecorderStatus(newTimerRunning ? "active" : "paused");

      if (newTimerRunning) {
        handleStopPrepTimer();
      }

      return newTimerRunning;
    });
  }, [handleStopPrepTimer]); // Remove timerRunning from dependencies

  const handleFinalCheck = useCallback(async () => {
    await submitFlow();
    setTimeout(() => {
      dispatch(fetchDebateById(debateId));
      dispatch({ type: "DEBATE_COMPLETED" });
    }, 1000);
    // dispatch(fetchDebateById(debateId));
    // dispatch({ type: "DEBATE_COMPLETED" });
  }, [debateId, dispatch]);

  const submitFlow = useCallback(() => {
    if (!isFlowSubmitted && getFlowData) {
      const flowData = getFlowData();
      dispatch(addFlow(debateId, flowData));
      setIsFlowSubmitted(true);
    }
  }, [debateId, dispatch, getFlowData, isFlowSubmitted]);

  const timerButtonAction = useCallback(() => {
    socket.emit("timerButtonPressed", { debateId, timerRunning });
  }, [socket, debateId]);

  const handleEndSpeech = useCallback(() => {
    setRecorderStatus("ended");
    setRecordingAllowed(false);
    const newSpeechIndex = currentSpeechIndex + 1;
    socket.emit("endSpeech", { debateId, newSpeechIndex });
  }, [currentSpeechIndex, socket, debateId]);

  const handleTranscriptionComplete = useCallback(
    async (transcription, speechIndex) => {
      const isLastTranscript = speechIndex === phases.length - 1;
      console.log(speechIndex, phases.length, isLastTranscript);
      setRecorderStatus("inactive");
      try {
        dispatch(
          addTranscript(
            debateId,
            {
              speechIndex,
              speaker: userName,
              content: transcription,
            },
            isLastTranscript
          )
        );
      } catch (error) {
        console.error("Error adding transcript:", error);
      }
    },
    [debateId, userName, phases.length, socket]
  );

  useEffect(() => {
    console.log("Recorder status changed to:", recorderStatus); // Add this log
  }, [recorderStatus]);

  return (
    <div>
      {currentSpeechIndex < phases.length ? (
        <>
          <h2>{currentSpeech?.name}</h2>
          <TimerDisplay
            timeLeft={timeLeft}
            teamPrepTimes={teamPrepTimes}
            isJudge={isJudge}
            prepTimeActive={prepTimeActive}
          />
          {isController && (
            <TimerControls
              timerRunning={timerRunning}
              onTimerAction={timerButtonAction}
              onEndSpeech={handleEndSpeech}
              canEndSpeech={timerRunning || timeLeft === 0}
            />
          )}
          {isActiveSpeaker && !isMultipleSpeakers && (
            <AudioRecorder
              status={recorderStatus}
              debateId={debateId}
              speechIndex={currentSpeechIndex}
              onTranscriptionComplete={handleTranscriptionComplete}
              recordingAllowed={recordingAllowed}
            />
          )}
        </>
      ) : (
        <>
          <Typography variant="h4">Debate Complete</Typography>
          {isFlowSubmitted ? (
            <Typography variant="body1">
              Your flow has been submitted.
            </Typography>
          ) : (
            <Typography variant="body1">Submitting your flow...</Typography>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(SpeechTimer);
