import React from "react";

const FormattedTimer = ({ timeLeft }) => {
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <h3>
      Time Left: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </h3>
  );
};

export default FormattedTimer;
