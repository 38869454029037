import {
  Box,
  List,
  Typography,
  ListItem,
  Link,
  styled,
  Stack,
} from "@mui/material";
import unitRegistry from "../static/unitRegistry";
import unitArticlesRegistry from "../static/unitArticlesRegistry";
import { Square, SquareCheckBig } from "lucide-react";
import { useSelector } from "react-redux";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
  "&:hover": {
    textDecoration: "underline",
  },
}));
const Unit = ({ unitRoute, articleRoute }) => {
  let unitNumber = unitRegistry.findIndex((unit) => unit.route === unitRoute);
  let unitTitle = unitRegistry[unitNumber]?.title;
  let unitArticles = unitArticlesRegistry?.[unitRoute] ?? [];
  const articleStates = useSelector((state) => state.articleStates?.data);
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ pt: 2, pl: 3 }}>
        <Typography
          variant="overline"
          sx={{
            fontWeight: articleRoute == null ? "bold" : "normal",
          }}
        >
          <Link
            href={"/learn/" + unitRoute}
            sx={{
              textDecoration: "none", // Remove default underline
              color: "inherit", // Inherit color from parent
              "&:hover": {
                textDecoration: "underline", // Add underline on hover
                color: "primary.main", // Change color on hover if needed
              },
            }}
          >
            Unit {unitNumber + 1}: {unitTitle}
          </Link>
        </Typography>
      </Box>
      <List
        sx={{
          pl: 3,
          pt: 0,
          pb: 2,
        }}
      >
        {unitArticles.map((item, index) => (
          <ListItem
            key={index}
            component={StyledLink}
            href={"/learn/" + unitRoute + "/" + item.route}
          >
            <Stack direction="row" spacing={1}>
              {userInfo && (
                <Box sx={{ flexShrink: 0, fontSize: "0.5em" }}>
                  {articleStates?.[unitRoute]?.[item.route]?.complete ? (
                    <SquareCheckBig></SquareCheckBig>
                  ) : (
                    <Square></Square>
                  )}
                </Box>
              )}
              <Typography
                sx={{
                  fontWeight: articleRoute === item.route ? "bold" : "normal",
                }}
              >
                {item.title}
              </Typography>
            </Stack>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Unit;
