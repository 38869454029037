import {
  FETCH_TURN_CREDENTIALS_REQUEST,
  FETCH_TURN_CREDENTIALS_SUCCESS,
  FETCH_TURN_CREDENTIALS_FAIL,
} from "../constants/turnConstants";

export const turnCredentialsReducer = (state = { iceServers: [] }, action) => {
  switch (action.type) {
    case FETCH_TURN_CREDENTIALS_REQUEST:
      return { loading: true };
    case FETCH_TURN_CREDENTIALS_SUCCESS:
      return { loading: false, iceServers: action.payload };
    case FETCH_TURN_CREDENTIALS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
