import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { SocketContext } from "../context/socket";
import { useWebRTC } from "../hooks/useWebRTC";
import AudioControls from "./AudioControls";
import ConnectionStatus from "./ConnectionStatus";
import { fetchTURNCredentials } from "../redux/actions/turnActions";
import { usePreptimeAudio } from "../hooks/usePreptimeAudio";
import { Typography } from "@mui/material";

const useTURNCredentials = (debateId) => {
  const dispatch = useDispatch();
  const { iceServers, loading, error } = useSelector(
    (state) => state.turnCredentials
  );
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    dispatch(fetchTURNCredentials(debateId));
  }, [debateId]);

  useEffect(() => {
    if (
      !loading &&
      iceServers &&
      iceServers.urls &&
      iceServers.urls.length > 0
    ) {
      setIsReady(true);
    } else if (!loading && error) {
      console.error("Error fetching TURN credentials:", error);
    }
  }, [loading, iceServers, error]);

  return { iceServers, isReady, error };
};

const WebRTCConnection = ({ debateId }) => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { participants, debateStarted, prepTimeActive } = useSelector(
    (state) => state.socket
  );

  const {
    iceServers,
    isReady: isTURNReady,
    error: turnError,
  } = useTURNCredentials(debateId);

  const [isMuted, setIsMuted] = useState(false);
  const [remoteStreams, setRemoteStreams] = useState({});
  const [connectionStates, setConnectionStates] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);

  const localStreamRef = useRef(null);
  const peersRef = useRef({});
  const initializationPromiseRef = useRef(null);

  const [isConnected, setIsConnected] = useState(false);
  const [connectionState, setConnectionState] = useState("New");

  const audioRefs = useRef({});

  const [signalQueue, setSignalQueue] = useState([]);

  const initializeLocalStream = useCallback(async () => {
    if (localStreamRef.current) {
      console.log("Local stream already initialized");
      return localStreamRef.current;
    }

    if (initializationPromiseRef.current) {
      console.log("Stream initialization already in progress");
      return initializationPromiseRef.current;
    }

    console.log("Initializing local stream...");
    initializationPromiseRef.current = navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    try {
      const stream = await initializationPromiseRef.current;
      console.log("Local stream obtained", stream);
      localStreamRef.current = stream;
      return stream;
    } catch (error) {
      console.error("Error accessing audio devices.", error);
      setError(
        "Unable to access your microphone. Please check your permissions and try again."
      );
      throw error;
    } finally {
      initializationPromiseRef.current = null;
    }
  }, []);

  const lobbyParticipantCount = useMemo(() => {
    return participants.filter((p) => p.isInLobby).length;
  }, [participants]);

  const {
    handleUserJoined,
    handleSignal,
    createPeer,
    handleReconnection,
    useWebRTCError,
  } = useWebRTC(
    debateId,
    userInfo,
    iceServers,
    socket,
    localStreamRef,
    peersRef,
    setRemoteStreams,
    setConnectionStates,
    initializeLocalStream,
    audioRefs,
    isTURNReady,
    signalQueue,
    setSignalQueue
  );

  const handleManualReconnect = useCallback(() => {
    console.log("Attempting manual reconnection");
    Object.entries(peersRef.current).forEach(([userId, peer]) => {
      peer.destroy();
      delete peersRef.current[userId];
      const newPeer = createPeer(userId, localStreamRef.current);
      peersRef.current[userId] = newPeer;
    });
  }, [createPeer]);

  const { setPrepAudio } = usePreptimeAudio(
    participants,
    prepTimeActive,
    debateStarted,
    userInfo,
    remoteStreams,
    audioRefs
  );

  useEffect(() => {
    let isMounted = true;

    const setup = async () => {
      if (isInitialized || !isTURNReady) return;

      try {
        console.log("TURN credentials ready, initializing local stream");
        const stream = await initializeLocalStream();
        if (!isMounted) return;

        if (stream) {
          console.log("Local stream initialized");
          setIsInitialized(true);
        } else {
          throw new Error("Failed to initialize local stream");
        }
      } catch (err) {
        console.error("Error during WebRTC setup:", err);
        setError(err.message);
      }
    };

    setup();

    return () => {
      isMounted = false;
      console.log("Cleaning up WebRTCConnection");
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach((track) => {
          track.stop();
          console.log(`Stopped track: ${track.kind}`);
        });
        localStreamRef.current = null;
      }
      Object.values(peersRef.current).forEach((peer) => {
        peer.destroy();
      });
      peersRef.current = {};
      setRemoteStreams({});
      setConnectionStates({});
      setIsInitialized(false);
    };
  }, [isTURNReady, initializeLocalStream]);

  useEffect(() => {
    console.log(1);
    console.log("prepTimeActive changed:", prepTimeActive);
    setPrepAudio();
  }, [prepTimeActive]);

  useEffect(() => {
    socket.on("userJoined", handleUserJoined);
    socket.on("signal", (signal) => {
      if (isTURNReady) {
        console.log(iceServers?.urls);
        handleSignal(signal);
      } else {
        console.log("Queueing signal", signal);
        setSignalQueue((prev) => [...prev, signal]);
      }
    });

    return () => {
      socket.off("userJoined", handleUserJoined);
      socket.off("signal");
    };
  }, [socket, handleUserJoined, handleSignal, isTURNReady]);

  useEffect(() => {
    if (isTURNReady && signalQueue.length > 0) {
      console.log("Processing queued signals", signalQueue);
      signalQueue.forEach((signal) => handleSignal(signal));
      setSignalQueue([]);
    }
  }, [isTURNReady, signalQueue, handleSignal]);

  useEffect(() => {
    const updateConnectionState = (states) => {
      const allStates = Object.values(states);
      if (allStates.includes("connected")) {
        setIsConnected(true);
        setConnectionState("Connected");
      } else if (allStates.includes("connecting")) {
        setIsConnected(false);
        setConnectionState("Connecting");
      } else {
        setIsConnected(false);
        setConnectionState("Disconnected");
      }
    };

    updateConnectionState(connectionStates);
  }, [connectionStates]);

  useEffect(() => {
    Object.entries(remoteStreams).forEach(([userId, stream]) => {
      if (audioRefs.current[userId]) {
        audioRefs.current[userId].srcObject = stream;
      }
    });
  }, [remoteStreams]);

  const handleMuteChange = useCallback((newMuteState) => {
    console.log("Mute state changed:", newMuteState);
    setIsMuted(newMuteState);
    if (localStreamRef.current) {
      const audioTrack = localStreamRef.current.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !newMuteState;
      }
    }
    console.log(
      "Local stream audio track enabled:",
      localStreamRef.current.getAudioTracks()[0].enabled
    );
  }, []);

  if (turnError) {
    return (
      <div>
        Error: Failed to fetch TURN credentials. Please try refreshing the page.
      </div>
    );
  }

  if (!isTURNReady || !isInitialized) {
    return <div>Initializing WebRTC connection...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <ConnectionStatus
        isConnected={isConnected}
        connectionState={connectionState}
        onReconnect={handleManualReconnect}
        participantCount={lobbyParticipantCount}
      />
      <AudioControls
        localStream={localStreamRef.current}
        isMuted={isMuted}
        onMuteChange={handleMuteChange}
      />
      {Object.entries(remoteStreams).map(([userId, stream]) => (
        <audio
          key={userId}
          ref={(el) => {
            if (el) {
              audioRefs.current[userId] = el;
              el.srcObject = stream;
              el.play().catch((e) => console.error("Error playing audio:", e));
            }
          }}
          autoPlay
          playsInline
        />
      ))}
    </div>
  );
};

export default React.memo(WebRTCConnection);
