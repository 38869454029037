import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDebateById } from "../redux/actions/debateActions";
import { initializeSocket, closeSocket } from "../redux/actions/socketActions";
import { Container, Spinner, Alert, Button } from "react-bootstrap";
import WebRTCConnection from "../components/WebRTCConnection";
import ParticipantsList from "../components/ParticipantsList";
import JudgesList from "../components/JudgesList";
import RoleAssignment from "../components/RoleAssignment";
import CoinFlip from "../components/CoinFlip";
import DebateDetails from "../components/DebateDetails";
import SpeechTimer from "../components/SpeechTimer";
import UserFlowGrid from "../components/UserFlowGrid";
import Vote from "../components/Vote";
import TranscriptViewer from "../components/TranscriptViewer";
import ReadFlowGrid from "../components/ReadFlowGrid";
import DebateChat from "../components/DebateChat";
import axios from "axios";
import { SocketContext } from "../context/socket";

const ActiveDebateScreen = () => {
  const socket = useContext(SocketContext);
  const { debateId } = useParams();
  const dispatch = useDispatch();
  const { selectedDebate, isLoading, error, allTranscriptsLoaded } =
    useSelector((state) => state.debates);
  const { userInfo } = useSelector((state) => state.auth);
  const { participants, debateStarted, roles, debateEnded } = useSelector(
    (state) => state.socket
  );

  const [debateFormat, setDebateFormat] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const userFlowGridRef = useRef(null);

  useEffect(() => {
    dispatch(fetchDebateById(debateId));
  }, [debateId]);

  useEffect(() => {
    if (selectedDebate && userInfo) {
      console.log("Initializing socket connection");
      dispatch(initializeSocket(debateId, userInfo));

      return () => {
        console.log("Closing socket connection");
        dispatch(closeSocket());
      };
    }
  }, [selectedDebate?._id, userInfo]);

  const fetchDebateFormat = useCallback(async () => {
    if (selectedDebate) {
      try {
        const response = await axios.get(
          `/api/debate-formats/${selectedDebate.event}`
        );
        setDebateFormat(response.data);
      } catch (error) {
        console.error("Error fetching debate format:", error);
      }
    }
  }, [selectedDebate]);

  useEffect(() => {
    fetchDebateFormat();
  }, [fetchDebateFormat]);

  //Gets the UserFlowGrid data
  const getFlowData = () => {
    return userFlowGridRef.current ? userFlowGridRef.current.getFlowData() : [];
  };

  const getCreatorTeam = () => {
    const creatorRole = participants.find(
      (participant) => participant.userId === selectedDebate.creator.userId
    )?.role;
    return creatorRole && (creatorRole.includes("teamA") ? "A" : "B");
  };

  const startDebateHandler = () => {
    let roles = {};

    if (
      selectedDebate.event === "Lincoln-Douglas" ||
      selectedDebate.event === "Extemporaneous"
    ) {
      const creatorRole =
        selectedDebate.side === "Aff" ? "Affirmative" : "Negative";
      const opponentRole =
        selectedDebate.side === "Aff" ? "Negative" : "Affirmative";
      const creator = participants.find(
        (p) => p.userId === selectedDebate.creator.userId
      );
      const opponent = participants.find(
        (p) => p.userId !== selectedDebate.creator.userId
      );

      roles = {
        [creatorRole]: creator,
        [opponentRole]: opponent,
      };
      socket.emit("finalizeRoles", {
        debateId,
        roles: roles,
        event: selectedDebate.event,
      });
    } else if (selectedDebate.event === "Policy") {
      const creatorSide =
        selectedDebate.side === "Aff" ? "Affirmative" : "Negative";
      const opponentSide =
        selectedDebate.side === "Aff" ? "Negative" : "Affirmative";
      const creatorTeam = getCreatorTeam();
      const opposingTeam = creatorTeam === "A" ? "B" : "A";

      const creatorSpeaker1 = participants.find(
        (p) => p.role === `team${creatorTeam}1`
      );
      const creatorSpeaker2 = participants.find(
        (p) => p.role === `team${creatorTeam}2`
      );
      const opponentSpeaker1 = participants.find(
        (p) => p.role === `team${opposingTeam}1`
      );
      const opponentSpeaker2 = participants.find(
        (p) => p.role === `team${opposingTeam}2`
      );

      roles = {
        [`${creatorSide} Speaker 1`]: creatorSpeaker1,
        [`${creatorSide} Speaker 2`]: creatorSpeaker2,
        [`${opponentSide} Speaker 1`]: opponentSpeaker1,
        [`${opponentSide} Speaker 2`]: opponentSpeaker2,
      };
      socket.emit("finalizeRoles", {
        debateId,
        roles: roles,
        event: selectedDebate.event,
      });
    } else if (
      selectedDebate.event === "Public Forum" &&
      selectedDebate.side !== "Coinflip"
    ) {
      const creatorSide =
        selectedDebate.speakerOrder === "First" ? "Team A" : "Team B";
      const opponentSide =
        selectedDebate.speakerOrder === "First" ? "Team B" : "Team A";

      const creatorTeam = getCreatorTeam();
      const opposingTeam = creatorTeam === "A" ? "B" : "A";

      const creatorSpeaker1 = participants.find(
        (p) => p.role === `team${creatorTeam}1`
      );
      const creatorSpeaker2 = participants.find(
        (p) => p.role === `team${creatorTeam}2`
      );
      const opponentSpeaker1 = participants.find(
        (p) => p.role === `team${opposingTeam}1`
      );
      const opponentSpeaker2 = participants.find(
        (p) => p.role === `team${opposingTeam}2`
      );

      roles = {
        [`${creatorSide} Speaker 1`]: creatorSpeaker1,
        [`${creatorSide} Speaker 2`]: creatorSpeaker2,
        [`${opponentSide} Speaker 1`]: opponentSpeaker1,
        [`${opponentSide} Speaker 2`]: opponentSpeaker2,
      };
      socket.emit("finalizeRoles", {
        debateId,
        roles: roles,
        event: selectedDebate.event,
      });
    }
    console.log("Debate Format", debateFormat);

    socket.emit("startDebate", {
      debateId,
    });
  };

  const handleReadyStatus = () => {
    const userRole = participants.find(
      (participant) => participant.userId === userInfo._id
    )?.role;

    if (
      (selectedDebate.event === "Public Forum" ||
        selectedDebate.event === "Policy") &&
      !userRole
    ) {
      setErrorMessage("Please select your team and speaking order");
    } else {
      socket.emit("setReadyStatus", { debateId, userId: userInfo._id });
      setErrorMessage("");
    }
  };

  const isParticipant = useMemo(() => {
    return selectedDebate?.participants.some(
      (participant) => participant.userId === userInfo._id
    );
  }, [selectedDebate, userInfo]);

  const isJudge = useMemo(() => {
    return selectedDebate?.judges.some(
      (judge) => judge.userId === userInfo._id
    );
  }, [selectedDebate, userInfo]);

  const allParticipantsReady = useMemo(() => {
    const requiredReadyCount =
      selectedDebate?.event === "Public Forum" ||
      selectedDebate?.event === "Policy"
        ? 4
        : 2;
    return participants.filter((p) => p.isReady).length === requiredReadyCount;
  }, [participants, selectedDebate]);

  const MemoizedWebRTCConnection = useMemo(
    () => (
      <WebRTCConnection
        debateId={debateId}
        userInfo={userInfo}
        participants={participants}
        debateStarted={debateStarted}
      />
    ),
    [debateId]
  );

  if (isLoading) return <Spinner animation="border" />;
  if (error) return <Alert variant="danger">{error}</Alert>;
  if (!selectedDebate) return null;

  return (
    <Container>
      <h1>{selectedDebate.resolution}</h1>
      {MemoizedWebRTCConnection}

      {!debateStarted ? (
        <>
          <DebateDetails
            event={selectedDebate.event}
            dateTime={selectedDebate.dateTime}
            side={selectedDebate.side}
            creatorName={selectedDebate.creator.name}
          />
          <h2>Debaters</h2>
          <ParticipantsList />
          <JudgesList />
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Button onClick={handleReadyStatus}>
            {participants.find((p) => p.userId === userInfo._id)?.isReady
              ? "Unready"
              : "Ready"}
          </Button>
          {isParticipant &&
            (selectedDebate.event === "Public Forum" ||
              selectedDebate.event === "Policy") && (
              <RoleAssignment
                debateId={debateId}
                userId={userInfo._id}
                userName={userInfo.name}
                event={selectedDebate.event}
              />
            )}
          {allParticipantsReady &&
            (selectedDebate.event === "Public Forum" &&
            selectedDebate.side === "Coinflip" ? (
              <CoinFlip
                debateId={debateId}
                user={userInfo}
                startDebateHandler={startDebateHandler}
              />
            ) : (
              <Button onClick={startDebateHandler}>Start Debate</Button>
            ))}
        </>
      ) : (
        <>
          {!debateEnded && (
            <>
              <SpeechTimer
                phases={debateFormat?.phases}
                event={selectedDebate.event}
                debateId={debateId}
                userId={userInfo._id}
                roles={roles}
                totalPrep={debateFormat?.prepTime}
                side={selectedDebate.side}
                isJudge={isJudge}
                userName={userInfo.name}
                getFlowData={getFlowData}
              />
              <UserFlowGrid
                ref={userFlowGridRef}
                debateStarted={debateStarted}
                event={selectedDebate.event}
                isSubmitted={debateEnded}
              />
            </>
          )}
          {debateEnded && (
            <>
              {isJudge && <Vote debateId={debateId} />}
              <TranscriptViewer
                debateId={debateId}
                phases={debateFormat?.phases}
              />
              <ReadFlowGrid
                selectedDebate={selectedDebate}
                userId={userInfo._id}
                event={selectedDebate.event}
                debateId={debateId}
              />
            </>
          )}
        </>
      )}
      <DebateChat
        debateId={debateId}
        userId={userInfo._id}
        userName={userInfo.name}
        userInfo={userInfo}
      />
    </Container>
  );
};

export default ActiveDebateScreen;
