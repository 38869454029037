import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listDebates } from "../redux/actions/debateActions";
import {
  Container,
  ListGroup,
  Spinner,
  Alert,
  Modal,
  Form,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DebateItem from "../components/DebateItem";
import PastDebateModal from "../components/PastDebateModal";
import useDebateActions from "../hooks/useDebateActions";

const MyDebatesScreen = () => {
  const dispatch = useDispatch();
  const { scheduledDebates, isLoading, error } = useSelector(
    (state) => state.debates
  );
  const { userInfo } = useSelector((state) => state.auth);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDebateId, setSelectedDebateId] = useState(null);
  const [joinCode, setJoinCode] = useState("");
  const [joinError, setJoinError] = useState("");
  const [activeTab, setActiveTab] = useState("upcoming");
  const [showPastDebateModal, setShowPastDebateModal] = useState(false);

  const {
    handleRegister,
    handleUnregister,
    handleRegisterAsJudge,
    handleUnregisterAsJudge,
    handleDelete,
    navigate,
  } = useDebateActions(setShowDeleteModal);

  useEffect(() => {
    dispatch(listDebates());
  }, [dispatch]);

  const filterDebates = (debates) => {
    const now = new Date();
    return debates.filter((debate) => {
      const isUserInvolved =
        debate.participants.some(
          (participant) =>
            participant.userId?.toString() === userInfo?._id?.toString()
        ) ||
        debate.judges.some(
          (judge) => judge.userId?.toString() === userInfo?._id?.toString()
        );

      const debateDate = new Date(debate.dateTime);
      const isPastDebate = debateDate < now || debate.completed;

      return (
        isUserInvolved &&
        ((activeTab === "upcoming" && !isPastDebate) ||
          (activeTab === "past" && isPastDebate))
      );
    });
  };

  const handleJoinDebate = (debate) => {
    if (
      debate.isOpen ||
      debate.participants.some(
        (participant) =>
          participant?._id?.toString() === userInfo?._id?.toString()
      )
    ) {
      navigate(`/active-debate/${debate._id}`);
    } else {
      setSelectedDebateId(debate._id);
      setShowModal(true);
    }
  };

  const handleJoinCodeSubmit = () => {
    const selectedDebate = scheduledDebates.find(
      (debate) => debate._id === selectedDebateId
    );
    if (joinCode === selectedDebate.joinCode) {
      setShowModal(false);
      navigate(`/active-debate/${selectedDebateId}`);
    } else {
      setJoinError("Incorrect join code");
    }
  };

  const handleShowDeleteModal = (debateId) => {
    setSelectedDebateId(debateId);
    setShowDeleteModal(true);
  };

  const handleViewPastDebate = (debateId) => {
    setSelectedDebateId(debateId);
    setShowPastDebateModal(true);
  };

  const filteredDebates = filterDebates(scheduledDebates);

  return (
    <>
      <ToastContainer />
      <Container>
        <h1>My Debates</h1>
        <ButtonGroup className="mb-3">
          <Button
            variant={activeTab === "upcoming" ? "primary" : "secondary"}
            onClick={() => setActiveTab("upcoming")}
          >
            Upcoming Debates
          </Button>
          <Button
            variant={activeTab === "past" ? "primary" : "secondary"}
            onClick={() => setActiveTab("past")}
          >
            Past Debates
          </Button>
        </ButtonGroup>
        {isLoading ? (
          <Spinner animation="border" />
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : filteredDebates.length > 0 ? (
          <ListGroup>
            {filteredDebates.map((debate) => {
              const isCreator =
                debate.creator?.userId?.toString() ===
                userInfo?._id?.toString();
              const isRegistered = debate.participants.some(
                (participant) =>
                  participant?.userId?.toString() === userInfo?._id?.toString()
              );
              const isRegisteredAsJudge = debate.judges.some(
                (judge) =>
                  judge?.userId?.toString() === userInfo?._id?.toString()
              );
              return (
                <ListGroup.Item key={debate._id}>
                  <DebateItem
                    debate={debate}
                    isCreator={isCreator}
                    isRegistered={isRegistered}
                    isRegisteredAsJudge={isRegisteredAsJudge}
                    handleJoinDebate={handleJoinDebate}
                    handleUnregister={handleUnregister}
                    handleRegister={handleRegister}
                    handleRegisterAsJudge={handleRegisterAsJudge}
                    handleUnregisterAsJudge={handleUnregisterAsJudge}
                    handleShowDeleteModal={handleShowDeleteModal}
                    handleViewPastDebate={handleViewPastDebate}
                    isPastDebate={activeTab === "past"}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : (
          <Alert variant="info">No {activeTab} debates found.</Alert>
        )}

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Join Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="joinCode">
                <Form.Label>Join Code</Form.Label>
                <Form.Control
                  type="text"
                  value={joinCode}
                  onChange={(e) => setJoinCode(e.target.value)}
                  placeholder="Enter join code"
                />
              </Form.Group>
              {joinError && <Alert variant="danger">{joinError}</Alert>}
              <Button variant="primary" onClick={handleJoinCodeSubmit}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Cancellation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to cancel this debate?</p>
            <Button
              variant="danger"
              onClick={() => handleDelete(selectedDebateId)}
            >
              Yes, Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              No, Go Back
            </Button>
          </Modal.Body>
        </Modal>

        <PastDebateModal
          show={showPastDebateModal}
          onHide={() => setShowPastDebateModal(false)}
          debateId={selectedDebateId}
          userId={userInfo?._id}
        />
      </Container>
    </>
  );
};

export default MyDebatesScreen;
