import axios from "axios";
import {
  GET_ARTICLE_STATES_REQUEST,
  GET_ARTICLE_STATES_SUCCESS,
  GET_ARTICLE_STATES_FAIL,
} from "../constants/articleStatesConstants";

export const getArticleStates = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ARTICLE_STATES_REQUEST });

    const {
      auth: { userInfo },
    } = getState();
    if (!userInfo || !userInfo.token) {
      dispatch({ type: GET_ARTICLE_STATES_SUCCESS, payload: "logout" });
      return;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Make the API call to create a debate
    const { data } = await axios.get(
      "/api/article-states/all",
      config // Use the config object here
    );

    dispatch({ type: GET_ARTICLE_STATES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ARTICLE_STATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
