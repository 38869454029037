import React, { useEffect, useState, useCallback } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchDebateById } from "../redux/actions/debateActions";
import ReadFlowGrid from "./ReadFlowGrid";
import TranscriptViewer from "./TranscriptViewer";
import axios from "axios";

const PastDebateModal = ({ show, onHide, debateId, userId }) => {
  const dispatch = useDispatch();
  const [debateFormat, setDebateFormat] = useState(null);
  const { selectedDebate, isLoading, error } = useSelector(
    (state) => state.debates
  );

  const [activeView, setActiveView] = useState("flow");

  useEffect(() => {
    if (debateId) {
      dispatch(fetchDebateById(debateId));
    }
  }, [debateId]);

  const fetchDebateFormat = useCallback(async () => {
    if (selectedDebate) {
      try {
        const response = await axios.get(
          `/api/debate-formats/${selectedDebate.event}`
        );
        setDebateFormat(response.data);
      } catch (error) {
        console.error("Error fetching debate format:", error);
      }
    }
  }, [selectedDebate]);

  useEffect(() => {
    console.log("Fetching debate format...");
    fetchDebateFormat();
  }, [fetchDebateFormat]);

  if (isLoading) {
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <Spinner animation="border" />
        </Modal.Body>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <p>Error loading debate details: {error}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  if (!selectedDebate) {
    return null;
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{selectedDebate.resolution}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <Button
            variant={activeView === "flow" ? "primary" : "secondary"}
            onClick={() => setActiveView("flow")}
            className="me-2"
          >
            View Flow
          </Button>
          <Button
            variant={activeView === "transcript" ? "primary" : "secondary"}
            onClick={() => setActiveView("transcript")}
          >
            View Transcript
          </Button>
        </div>
        {activeView === "flow" ? (
          <ReadFlowGrid
            selectedDebate={selectedDebate}
            userId={userId}
            event={selectedDebate.event}
          />
        ) : (
          <TranscriptViewer debateId={debateId} phases={debateFormat?.phases} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PastDebateModal;
