import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import authReducer from "./reducers/authReducer";
import debateReducer from "./reducers/debateReducer";
import { userRegisterReducer } from "./reducers/userReducers";
import { socketReducer } from "./reducers/socketReducer";
import { coinFlipReducer } from "./reducers/coinFlipReducer";
import messageDevReducer from "./reducers/messageDevReducer";
import { turnCredentialsReducer } from "./reducers/turnReducer";
import socketMiddleware from "./middleware/socketMiddleware";
import { articleStatesReducer } from "./reducers/articleStatesReducer";

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  auth: { userInfo: userInfoFromStorage },
};

const rootReducer = {
  userRegister: userRegisterReducer,
  auth: authReducer,
  debates: debateReducer,
  socket: socketReducer,
  coinFlip: coinFlipReducer,
  messageDev: messageDevReducer,
  turnCredentials: turnCredentialsReducer,
  articleStates: articleStatesReducer,
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk, socketMiddleware),
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
});

export default store;
