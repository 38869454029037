import { Row, Col, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";

const ParticipantsList = () => {
  const participants = useSelector((state) => state.socket.participants);

  if (!participants) {
    return null;
  }

  const inLobbyParticipants = participants.filter(
    (participant) => participant.isInLobby
  );

  const waitingParticipants = participants.filter(
    (participant) => !participant.isInLobby
  );

  return (
    <Row>
      <Col md={6}>
        <h4>In Lobby</h4>
        <ListGroup>
          {inLobbyParticipants.map((participant) => (
            <ListGroup.Item key={participant.userId}>
              {participant.name} {participant.isReady && "✔️"}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
      <Col md={6}>
        <h4>Waiting for</h4>
        <ListGroup>
          {waitingParticipants.map((participant) => (
            <ListGroup.Item key={participant.userId}>
              {participant.name} {participant.isReady && "✔️"}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
    </Row>
  );
};

export default ParticipantsList;
