export const SOCKET_CONNECT = "SOCKET_CONNECT";
export const SOCKET_DISCONNECT = "SOCKET_DISCONNECT";
export const SOCKET_SEND_MESSAGE = "SOCKET_SEND_MESSAGE";
export const SOCKET_MESSAGE_RECEIVED = "SOCKET_MESSAGE_RECEIVED";
export const UPDATE_PARTICIPANTS = "UPDATE_PARTICIPANTS";
export const DEBATE_STARTED = "DEBATE_STARTED";
export const UPDATE_ROLES = "UPDATE_ROLES";
export const UPDATE_CURRENT_SPEECH_INDEX = "UPDATE_CURRENT_SPEECH_INDEX";
export const START_PREP_TIME = "START_PREP_TIME";
export const END_PREP_TIME = "END_PREP_TIME";
export const SIGNAL_RECEIVED = "SIGNAL_RECEIVED";
export const SEND_SIGNAL = "SEND_SIGNAL";
