import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { createMessage } from "../redux/actions/messageDevActions";

const MessageDevScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [agreeToEmails, setAgreeToEmails] = useState(false);
  const [open, setOpen] = useState(false); // State for snackbar notification
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const newMessage = { name, email, message, agreeToEmails };
    dispatch(createMessage(newMessage));

    // Show notification
    setOpen(true);

    // Clear fields
    setName("");
    setEmail("");
    setMessage("");
    setAgreeToEmails(false);
  };

  // Handle closing of Snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          paragraph
        >
          Have feedback or need assistance? Send us a message and we’ll get back
          to you shortly.
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Message"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeToEmails}
                onChange={(e) => setAgreeToEmails(e.target.checked)}
                color="primary"
              />
            }
            label="I agree to receive updates and notifications about Flow Debate."
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Send Message
          </Button>
        </form>
      </Box>
      {/* Snackbar for success message */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thank you for your feedback!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MessageDevScreen;
