import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  IconButton,
  Snackbar,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Link,
} from "@mui/material";
import {
  Send,
  Paperclip,
  Minimize,
  Maximize,
  FileText,
  Image,
  Film,
  Music,
  Download,
  X,
} from "lucide-react";
import FilePreview from "./FilePreview";
import { SocketContext } from "../context/socket";
import axios from "axios";

const DebateChat = ({ debateId, userId, userName, userInfo }) => {
  const socket = useContext(SocketContext);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isMinimized, setIsMinimized] = useState(false);
  const [newMessageAlert, setNewMessageAlert] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    socket.on("newChatMessage", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
      if (isMinimized) {
        setNewMessageAlert(true);
      }
    });

    socket.on("newFileShared", (fileInfo) => {
      setMessages((prevMessages) => [...prevMessages, fileInfo]);
      if (isMinimized) {
        setNewMessageAlert(true);
      }
    });

    return () => {
      socket.off("newChatMessage");
      socket.off("newFileShared");
    };
  }, [socket, isMinimized]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleFileClick = (message) => {
    setPreviewFile(message);
  };

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      const newMessage = {
        id: Date.now(),
        sender: userName,
        content: inputMessage,
        type: "text",
      };
      socket.emit("sendChatMessage", { debateId, message: newMessage });
      setInputMessage("");
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("File size should not exceed 5MB.");
        event.target.value = "";
        return;
      }

      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("debateId", debateId);

      try {
        const response = await axios.post("/api/files/upload", formData, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        const fileInfo = {
          id: Date.now(),
          sender: userName,
          content: response.data.filename,
          type: "file",
          fileId: response.data.fileId,
          fileType: response.data.fileType,
        };

        socket.emit("shareFile", { debateId, fileInfo });
      } catch (error) {
        console.error("File upload failed:", error);
        alert("File upload failed. Please try again.");
      } finally {
        setIsUploading(false);
        event.target.value = "";
      }
    }
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
    setNewMessageAlert(false);
  };

  const getFileIcon = (fileType) => {
    if (fileType.startsWith("image")) return <Image size={16} />;
    if (fileType.startsWith("video")) return <Film size={16} />;
    if (fileType.startsWith("audio")) return <Music size={16} />;
    return <FileText size={16} />;
  };

  return (
    <Paper
      elevation={3}
      sx={{
        position: "fixed",
        bottom: 20,
        right: 20,
        width: 300,
        height: isMinimized ? 40 : 400,
        bgcolor: "background.paper",
        borderRadius: "8px 8px 0 0",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography variant="h6">Debate Chat</Typography>
        <IconButton onClick={toggleMinimize}>
          {isMinimized ? <Maximize size={20} /> : <Minimize size={20} />}
        </IconButton>
      </Box>

      {!isMinimized && (
        <>
          <List
            ref={chatContainerRef}
            sx={{ flexGrow: 1, overflowY: "auto", padding: 1 }}
          >
            {messages.map((message) => (
              <ListItem
                key={message.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginBottom: 1,
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  {message.sender}
                </Typography>
                {message.type === "text" ? (
                  <Typography variant="body2">{message.content}</Typography>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "action.hover",
                      padding: 1,
                      borderRadius: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => handleFileClick(message)}
                  >
                    {getFileIcon(message.fileType)}
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {message.content}
                    </Typography>
                  </Box>
                )}
              </ListItem>
            ))}
          </List>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ mb: 1 }}
            align="center"
          >
            Recommended file types: PDF and TXT
          </Typography>
          <Box
            sx={{
              display: "flex",
              padding: 1,
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          >
            <TextField
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Type a message..."
              variant="outlined"
              size="small"
              fullWidth
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png,.gif"
            />
            <IconButton
              onClick={() => fileInputRef.current.click()}
              disabled={isUploading}
            >
              {isUploading ? (
                <CircularProgress size={20} />
              ) : (
                <Paperclip size={20} />
              )}
            </IconButton>
            <Button
              variant="contained"
              endIcon={<Send size={20} />}
              onClick={handleSendMessage}
              sx={{ ml: 1 }}
            >
              Send
            </Button>
          </Box>
        </>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isMinimized && newMessageAlert}
        message="New message received!"
        onClose={() => setNewMessageAlert(false)}
        autoHideDuration={3000}
      />

      {previewFile && (
        <FilePreview
          file={previewFile}
          onClose={() => setPreviewFile(null)}
          userInfo={userInfo}
        />
      )}
    </Paper>
  );
};

export default DebateChat;
