import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { WifiOff, Wifi, Users } from "lucide-react";

const ConnectionStatus = ({
  isConnected,
  onReconnect,
  connectionState,
  participantCount,
}) => {
  if (participantCount <= 1) {
    return (
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        p={2}
        bgcolor="#f0f0f0"
        borderRadius={2}
      >
        <Users size={24} />
        <Typography>Waiting for other participants to join...</Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      p={2}
      bgcolor="#f0f0f0"
      borderRadius={2}
    >
      {isConnected ? (
        <Wifi color="green" size={24} />
      ) : (
        <WifiOff color="red" size={24} />
      )}
      <Typography>
        {isConnected
          ? `Connected (${connectionState})`
          : `Disconnected (${connectionState})`}
      </Typography>
      {!isConnected && (
        <Button
          variant="contained"
          color="primary"
          onClick={onReconnect}
          size="small"
        >
          Reconnect
        </Button>
      )}
    </Box>
  );
};

export default ConnectionStatus;
