import axios from "axios";

import {
  CREATE_DEBATE_REQUEST,
  CREATE_DEBATE_SUCCESS,
  CREATE_DEBATE_FAIL,
  DEBATE_LIST_REQUEST,
  DEBATE_LIST_SUCCESS,
  DEBATE_LIST_FAIL,
  DEBATE_REGISTER_SUCCESS,
  DEBATE_REGISTER_FAIL,
  DEBATE_UNREGISTER_SUCCESS,
  DEBATE_UNREGISTER_FAIL,
  FETCH_DEBATE_REQUEST,
  FETCH_DEBATE_SUCCESS,
  FETCH_DEBATE_FAIL,
  DEBATE_DELETE_SUCCESS,
  DEBATE_DELETE_FAIL,
  REGISTER_JUDGE_SUCCESS,
  REGISTER_JUDGE_FAIL,
  UNREGISTER_JUDGE_SUCCESS,
  UNREGISTER_JUDGE_FAIL,
  ASSIGN_ROLE_SUCCESS,
  ASSIGN_ROLE_FAIL,
  UNASSIGN_ROLE_SUCCESS,
  UNASSIGN_ROLE_FAIL,
  RESET_SELECTED_DEBATE,
  VOTE_DEBATE_REQUEST,
  VOTE_DEBATE_SUCCESS,
  VOTE_DEBATE_FAIL,
  COMPLETE_DEBATE_REQUEST,
  COMPLETE_DEBATE_FAIL,
  COMPLETE_DEBATE_SUCCESS,
  ADD_TRANSCRIPT_REQUEST,
  ADD_TRANSCRIPT_SUCCESS,
  ADD_TRANSCRIPT_FAIL,
  ADD_FLOW_REQUEST,
  ADD_FLOW_SUCCESS,
  ADD_FLOW_FAIL,
} from "../constants/debateConstants.js";

// Create a new debate
export const createDebate = (debateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_DEBATE_REQUEST });

    const {
      auth: { userInfo },
    } = getState();

    // Debugging logs
    console.log("userInfo:", userInfo);
    console.log("debateData:", debateData);

    if (!userInfo || !userInfo.token) {
      throw new Error("User is not authenticated");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Make the API call to create a debate
    const { data } = await axios.post(
      "/api/debates/create",
      debateData,
      config // Use the config object here
    );

    dispatch({ type: CREATE_DEBATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_DEBATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get all debates
export const listDebates = () => async (dispatch) => {
  try {
    dispatch({ type: DEBATE_LIST_REQUEST });

    const { data } = await axios.get("/api/debates");

    dispatch({ type: DEBATE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DEBATE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Fetch Single Debate action
export const fetchDebateById = (debateId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_DEBATE_REQUEST });

    const { data } = await axios.get(`/api/debates/${debateId}`);

    dispatch({ type: FETCH_DEBATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FETCH_DEBATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetSelectedDebate = () => (dispatch) => {
  dispatch({ type: RESET_SELECTED_DEBATE });
};

// Register for a debate
export const registerForDebate = (debateId) => async (dispatch, getState) => {
  try {
    const {
      auth: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/debates/${debateId}/register`,
      { userId: userInfo._id, name: userInfo.name }, // Pass the user's name
      config
    );

    console.log("Register for debate data:", data);

    dispatch({ type: DEBATE_REGISTER_SUCCESS, payload: data });

    // Re-fetch the list of debates
    dispatch(listDebates());
  } catch (error) {
    dispatch({
      type: DEBATE_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Unregister from a debate
export const unregisterFromDebate =
  (debateId) => async (dispatch, getState) => {
    try {
      const {
        auth: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/debates/${debateId}/unregister`,
        {},
        config
      );

      dispatch({ type: DEBATE_UNREGISTER_SUCCESS, payload: data });

      // Re-fetch the list of debates
      dispatch(listDebates());
    } catch (error) {
      dispatch({
        type: DEBATE_UNREGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Register as a judge
export const registerAsJudge = (debateId) => async (dispatch, getState) => {
  try {
    const {
      auth: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/debates/${debateId}/register-judge`,
      { userId: userInfo._id, name: userInfo.name }, // Pass the user's name
      config
    );

    dispatch({ type: REGISTER_JUDGE_SUCCESS, payload: data });

    // Optionally refresh the debates list to update the UI
    dispatch(listDebates());
  } catch (error) {
    dispatch({
      type: REGISTER_JUDGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const unregisterAsJudge = (debateId) => async (dispatch, getState) => {
  try {
    const {
      auth: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/debates/${debateId}/unregister-judge`,
      {},
      config
    );

    dispatch({ type: UNREGISTER_JUDGE_SUCCESS, payload: data });

    // Optionally refresh the debates list to update the UI
    dispatch(listDebates());
  } catch (error) {
    dispatch({
      type: UNREGISTER_JUDGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Vote for a debate
export const voteForDebate =
  (debateId, winner, rfd) => async (dispatch, getState) => {
    try {
      dispatch({ type: VOTE_DEBATE_REQUEST });

      const {
        auth: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/debates/${debateId}/vote`,
        { debateId, winner, rfd },
        config
      );

      dispatch({ type: VOTE_DEBATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: VOTE_DEBATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Set debate to completed
export const completeDebate = (debateId) => async (dispatch, getState) => {
  try {
    dispatch({ type: COMPLETE_DEBATE_REQUEST });

    const {
      auth: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/debates/${debateId}/complete`,
      { debateId },
      config
    );

    dispatch({ type: COMPLETE_DEBATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPLETE_DEBATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Delete a debate
export const deleteDebate = (debateId) => async (dispatch, getState) => {
  try {
    const {
      auth: { userInfo },
    } = getState();
    const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };
    await axios.delete(`/api/debates/${debateId}`, config);
    dispatch({ type: DEBATE_DELETE_SUCCESS, payload: debateId });
    dispatch(listDebates());
  } catch (error) {
    dispatch({
      type: DEBATE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Handle coin flip
export const handleCoinFlip =
  (debateId, outcome, creatorSelection) => async (dispatch, getState) => {
    try {
      const {
        auth: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/debates/${debateId}/coin-flip`,
        { outcome, creatorSelection },
        config
      );

      dispatch({ type: FETCH_DEBATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: FETCH_DEBATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const assignRole =
  (debateId, role, userId, userName) => async (dispatch, getState) => {
    try {
      const {
        auth: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/debates/${debateId}/assign-role`,
        { role, userId, userName },
        config
      );

      dispatch({ type: ASSIGN_ROLE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ASSIGN_ROLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const unassignRole = (debateId, role) => async (dispatch, getState) => {
  try {
    const {
      auth: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/debates/${debateId}/unassign-role`,
      { role },
      config
    );

    dispatch({ type: UNASSIGN_ROLE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNASSIGN_ROLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Add a transcript
export const addTranscript =
  (debateId, transcriptData, isLastTranscript) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_TRANSCRIPT_REQUEST });

      const {
        auth: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/debates/${debateId}/transcripts`,
        transcriptData,
        config
      );

      dispatch({
        type: ADD_TRANSCRIPT_SUCCESS,
        payload: { data: transcriptData, isLastTranscript: isLastTranscript },
      });

      return data; // Return the updated debate data
    } catch (error) {
      dispatch({
        type: ADD_TRANSCRIPT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      throw error;
    }
  };

export const addFlow = (debateId, flowData) => async (dispatch, getState) => {
  console.log("Adding flow:", flowData);
  try {
    dispatch({ type: ADD_FLOW_REQUEST });

    const {
      auth: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `/api/debates/${debateId}/flows`,
      { flowData },
      config
    );

    dispatch({ type: ADD_FLOW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADD_FLOW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
