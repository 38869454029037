import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Container,
  Button,
  Avatar,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/userActions";
import { FaUser } from "react-icons/fa";

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.auth);
  const { userInfo } = userLogin;

  const [anchorEl, setAnchorEl] = useState(null);
  const [debateAnchorEl, setDebateAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [mobileDebateMenuAnchorEl, setMobileDebateMenuAnchorEl] =
    useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDebateMenuOpen = (event) => {
    setDebateAnchorEl(event.currentTarget);
  };

  const handleDebateMenuClose = () => {
    setDebateAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setMobileDebateMenuAnchorEl(null);
  };

  const handleMobileDebateMenuOpen = (event) => {
    setMobileDebateMenuAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleMenuClose();
    handleMobileMenuClose();
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{ flexGrow: 1, textDecoration: "none", color: "inherit" }}
          >
            Flow Debate
          </Typography>

          <Box sx={{ display: { xs: "none", md: "flex" }, ml: "auto" }}>
            <Button component={Link} to="/learn" color="inherit">
              Learn
            </Button>
            <Button
              aria-controls="debate-menu"
              aria-haspopup="true"
              onClick={handleDebateMenuOpen}
              color="inherit"
            >
              Debate
            </Button>
            <Button component={Link} to="/messageDev" color="inherit">
              Message Devs
            </Button>
            <Menu
              id="debate-menu"
              anchorEl={debateAnchorEl}
              keepMounted
              open={Boolean(debateAnchorEl)}
              onClose={handleDebateMenuClose}
            >
              <MenuItem
                component={Link}
                to="/create-debate"
                onClick={handleDebateMenuClose}
              >
                Create Debate
              </MenuItem>
              <MenuItem
                component={Link}
                to="/scheduled-debates"
                onClick={handleDebateMenuClose}
              >
                Join Debate
              </MenuItem>
            </Menu>
          </Box>

          {userInfo ? (
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <IconButton
                edge="end"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="inherit"
              >
                <Avatar alt={userInfo.name} src="/static/images/avatar/1.jpg" />
              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  component={Link}
                  to="/profile"
                  onClick={handleMenuClose}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/my-debates"
                  onClick={handleMenuClose}
                >
                  My Debates
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <Button
                component={Link}
                to="/sign-in"
                color="inherit"
                startIcon={<FaUser />}
              >
                Sign In
              </Button>
              <Button component={Link} to="/sign-up" color="inherit">
                Create Account
              </Button>
            </Box>
          )}

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>

      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(mobileMoreAnchorEl)}
        onClose={handleMobileMenuClose}
      >
        <MenuItem component={Link} to="/learn">
          Learn
        </MenuItem>
        <MenuItem
          aria-controls="mobile-debate-menu"
          aria-haspopup="true"
          onClick={handleMobileDebateMenuOpen}
        >
          Debate
        </MenuItem>
        <Menu
          id="mobile-debate-menu"
          anchorEl={mobileDebateMenuAnchorEl}
          keepMounted
          open={Boolean(mobileDebateMenuAnchorEl)}
          onClose={handleMobileMenuClose}
        >
          <MenuItem
            component={Link}
            to="/create-debate"
            onClick={handleMobileMenuClose}
          >
            Create Debate
          </MenuItem>
          <MenuItem
            component={Link}
            to="/scheduled-debates"
            onClick={handleMobileMenuClose}
          >
            Join Debate
          </MenuItem>
        </Menu>
        <MenuItem component={Link} to="/messageDev">
          Message Devs
        </MenuItem>
        {userInfo ? (
          <Box>
            <MenuItem component={Link} to="/profile">
              Profile
            </MenuItem>
            <MenuItem component={Link} to="/my-debates">
              My Debates
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Box>
        ) : (
          <Box>
            <MenuItem component={Link} to="/sign-in">
              Sign In
            </MenuItem>
            <MenuItem component={Link} to="/sign-up">
              Create Account
            </MenuItem>
          </Box>
        )}
      </Menu>
    </AppBar>
  );
};

export default Header;
