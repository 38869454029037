import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  Grid,
  Paper,
  Box,
  Alert,
} from "@mui/material";
import { createDebate } from "../redux/actions/debateActions";
import axios from "axios";

const CreateDebateScreen = () => {
  const [resolution, setResolution] = useState("");
  const [event, setEvent] = useState("Lincoln-Douglas");
  const [description, setDescription] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [side, setSide] = useState("Aff");
  const [allowJudges, setAllowJudges] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [joinCode, setJoinCode] = useState("");
  const [speakerOrder, setSpeakerOrder] = useState("First");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, createDebateError } = useSelector(
    (state) => state.debates
  );

  useEffect(() => {
    if (!isOpen) {
      setJoinCode(generateJoinCode());
    } else {
      setJoinCode("");
    }
  }, [isOpen]);

  const addTopic = async () => {
    try {
      const { data } = await axios.get(`/api/topic/${event}`);
      setResolution(data.resolution);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const generateJoinCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    for (let i = 0; i < 6; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return code;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const dateTimeUTC = dateTime ? new Date(dateTime).toISOString() : null; // Convert to UTC
      const newDebate = {
        resolution,
        event,
        description,
        dateTime: dateTimeUTC,
        side,
        speakerOrder: event === "Public Forum" ? speakerOrder : undefined,
        allowJudges,
        isOpen,
        joinCode: isOpen ? null : joinCode,
      };

      dispatch(createDebate(newDebate));
      navigate("/scheduled-debates");
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!resolution.trim()) newErrors.resolution = "Resolution is required";
    if (!dateTime) newErrors.dateTime = "Date and time are required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Create Debate
        </Typography>
        {createDebateError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {createDebateError}
          </Alert>
        )}
        {Object.keys(errors).length > 0 && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Please fill in all required fields.
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Debate Event</FormLabel>
                <Select
                  value={event}
                  onChange={(e) => setEvent(e.target.value)}
                >
                  <MenuItem value="Lincoln-Douglas">Lincoln-Douglas</MenuItem>
                  <MenuItem value="Public Forum">Public Forum</MenuItem>
                  <MenuItem value="Policy">Policy</MenuItem>
                  {/* <MenuItem value="Extemporaneous">Extemporaneous</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10} md={10}>
              <TextField
                label="Debate Resolution"
                value={resolution}
                onChange={(e) => setResolution(e.target.value)}
                placeholder="Debate Resolution"
                fullWidth
                required
                multiline
                error={!!errors.resolution}
                helperText={errors.resolution}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                fullWidth={true} // Ensures the button takes full width on small screens
                onClick={addTopic}
              >
                Current Topic
              </Button>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Debate Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="e.g. Varisty Preferred, Progressive"
                fullWidth
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Debate Date and Time"
                type="datetime-local"
                value={dateTime}
                onChange={(e) => setDateTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                error={!!errors.dateTime}
                helperText={errors.dateTime}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Side Selection</FormLabel>
                <Select value={side} onChange={(e) => setSide(e.target.value)}>
                  <MenuItem value="Aff">Affirmative</MenuItem>
                  <MenuItem value="Neg">Negative</MenuItem>
                  {event === "Public Forum" && (
                    <MenuItem value="Coinflip">Coinflip</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {event === "Public Forum" && (side === "Aff" || side === "Neg") && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel>Speaker Order</FormLabel>
                  <Select
                    value={speakerOrder}
                    onChange={(e) => setSpeakerOrder(e.target.value)}
                  >
                    <MenuItem value="First">First</MenuItem>
                    <MenuItem value="Second">Second</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allowJudges}
                    onChange={(e) => setAllowJudges(e.target.checked)}
                  />
                }
                label="Allow Judges"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Debate Type</FormLabel>
                <RadioGroup
                  row
                  value={isOpen ? "Public" : "Private"}
                  onChange={(e) => setIsOpen(e.target.value === "Public")}
                >
                  <FormControlLabel
                    value="Public"
                    control={<Radio />}
                    label="Public"
                  />
                  <FormControlLabel
                    value="Private"
                    control={<Radio />}
                    label="Private"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {!isOpen && (
              <Grid item xs={12}>
                <TextField
                  label="Join Code"
                  value={joinCode}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={isLoading}
              >
                {isLoading ? "Creating..." : "Create Debate"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default CreateDebateScreen;
