import {
  MESSAGE_DEV_FAIL,
  MESSAGE_DEV_REQUEST,
  MESSAGE_DEV_SUCCESS,
} from "../constants/messageDevConstants";

const initialState = {
  message: null,
  error: null,
  isLoading: false,
};

const messageDevReducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_DEV_REQUEST:
      return { ...state, isLoading: true };
    case MESSAGE_DEV_SUCCESS:
      return { ...state, isLoading: false, message: action.payload };
    case MESSAGE_DEV_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};
export default messageDevReducer;
