import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { CircleDot, Check, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import MarkdownContext from "../MarkdownContext";

const Question = ({ question, options, correct }) => {
  const { contextValue, updateContextValue, setInitContextValue } =
    useContext(MarkdownContext);
  if (typeof options == "string") {
    options = JSON.parse(options);
  }
  if (typeof correct == "string") {
    correct = Number(correct);
  }
  const [selectedOption, setSelectedOption] = useState("");
  const [submissionState, setSubmissionState] = useState(
    contextValue.tag === "complete" ? "correct" : "none"
  );

  const questionKey =
    question +
    ":" +
    options.map((val, i) => (i === correct ? "@" + val : val)).join("|");

  useEffect(() => {
    updateSubmissionState(submissionState, true);
    if (submissionState === "correct") {
      setSelectedOption(options[correct]);
    }
  });

  function updateSubmissionState(submissionState, init = false) {
    let updateFunction = init ? setInitContextValue : updateContextValue;
    updateFunction((contextValue) => {
      contextValue.questions[questionKey] = {
        complete: submissionState === "correct" ? true : false,
      };
      return contextValue;
    });
    setSubmissionState(submissionState);
  }
  const handleChange = (event) => {
    let selectedOption = event.target.value;
    setSelectedOption(selectedOption);
    if (options.indexOf(selectedOption) === correct) {
      updateSubmissionState("correct");
    } else {
      updateSubmissionState("wrong");
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        my: 2,
      }}
    >
      <Box sx={{ p: 2, width: "100%" }}>
        <FormControl component="fieldset" sx={{ width: "100%" }}>
          <FormLabel component="legend">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                color:
                  submissionState === "none"
                    ? "text.secondary"
                    : submissionState === "correct"
                    ? "success.main"
                    : "error.main",
              }}
            >
              {submissionState === "none" ? (
                <CircleDot></CircleDot>
              ) : submissionState === "correct" ? (
                <Check></Check>
              ) : (
                <X></X>
              )}
              <Typography variant="h6">{question}</Typography>
            </Stack>
          </FormLabel>
          <RadioGroup value={selectedOption} onChange={handleChange}>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Card>
  );
};

export default Question;
