import {
  INITIATE_COIN_FLIP,
  COIN_FLIP_RESULT,
  SIDE_ORDER_SELECTED,
  SET_DECISION_MAKER,
  SET_STAGE,
} from "../constants/coinFlipConstants";

const initialState = {
  selection: null,
  result: null,
  coinTossWinner: null,
  sideOrderSelection: null,
  roles: {},
  decisionMaker: null,
  stage: 0,
};

export const coinFlipReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIATE_COIN_FLIP:
      return {
        ...state,
        selection: action.payload.selection,
      };
    case COIN_FLIP_RESULT:
      return {
        ...state,
        result: action.payload.result,
        coinTossWinner: action.payload.coinTossWinner,
        decisionMaker: action.payload.decisionMaker,
      };
    case SIDE_ORDER_SELECTED:
      return {
        ...state,
        sideOrderSelection: action.payload.selection,
        decisionMaker: action.payload.decisionMaker,
      };
    case SET_DECISION_MAKER:
      return {
        ...state,
        decisionMaker: action.payload,
      };
    case SET_STAGE:
      return {
        ...state,
        stage: action.payload,
      };
    default:
      return state;
  }
};
