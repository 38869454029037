import {
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  UPDATE_PARTICIPANTS,
  DEBATE_STARTED,
  UPDATE_ROLES,
  UPDATE_CURRENT_SPEECH_INDEX,
  START_PREP_TIME,
  END_PREP_TIME,
  SEND_SIGNAL,
} from "../constants/socketConstants";

// Action to initialize WebSocket connection
export const initializeSocket = (
  debateId,
  userInfo,
  shouldConnect = false
) => ({
  type: SOCKET_CONNECT,
  payload: {
    debateId,
    userInfo,
    shouldConnect,
    serverUrl: process.env.REACT_APP_SERVER_URL,
  },
});

export const closeSocket = () => ({
  type: SOCKET_DISCONNECT,
});

export const updateParticipants = (data) => ({
  type: UPDATE_PARTICIPANTS,
  payload: data,
});

export const updateRoles = (roles) => ({
  type: UPDATE_ROLES,
  payload: roles,
});

export const startDebate = () => ({
  type: DEBATE_STARTED,
});

export const updateCurrentSpeechIndex = (data) => ({
  type: UPDATE_CURRENT_SPEECH_INDEX,
  payload: data,
});

// Action to start prep time
export const startPrepTime = () => ({
  type: START_PREP_TIME,
});

// Action to end prep time
export const endPrepTime = () => ({
  type: END_PREP_TIME,
});

export const sendSignal = (to, from, signal) => ({
  type: SEND_SIGNAL,
  payload: { to, from, signal },
});
