import {
  Container,
  Typography,
  Box,
  Divider,
  Button,
  Stack,
  Drawer,
  Toolbar,
  Menu,
  MenuItem,
  ListItem,
  List,
  IconButton,
  Link,
} from '@mui/material';
import Unit from './Unit';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const ArticleDrawer = ({ isOpen, setIsOpen, drawerWidth, unit, route }) => {
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Drawer
        open={isOpen}
        variant="persistent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            mt: 8,
          },
        }}
      >
        <Unit unitRoute={unit} articleRoute={route}></Unit>
      </Drawer>
      {/* Chevron Icon to hide/show sidebar */}
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Full height for vertical centering
          width: isOpen ? 'auto' : '48px', // Space for chevron button when closed
          top: 0,
          left: 0,
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={{
            position: 'fixed',
            left: isOpen ? drawerWidth - 20 : 0, // Positioned relative to the sidebar's width
            transition: 'left 0.3s', // Smooth transition for chevron
            border: '1px solid',
            pointerEvents: 'all',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: (theme) => theme.palette.background.paper,
              zIndex: -100,
              borderRadius: '50%',
            },
          }}
        >
          {isOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Box>
    </>
  );
};

export default ArticleDrawer;
