import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listDebates } from "../redux/actions/debateActions";
import {
  Container,
  CircularProgress,
  Alert,
  Modal,
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DebateItem from "../components/DebateItem";
import useDebateActions from "../hooks/useDebateActions";
import moment from "moment";

const ScheduledDebatesScreen = () => {
  const dispatch = useDispatch();
  const { scheduledDebates, isLoading, error } = useSelector(
    (state) => state.debates
  );
  const { userInfo } = useSelector((state) => state.auth);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDebateId, setSelectedDebateId] = useState(null);
  const [joinCode, setJoinCode] = useState("");
  const [joinError, setJoinError] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");

  const {
    handleRegister,
    handleUnregister,
    handleRegisterAsJudge,
    handleUnregisterAsJudge,
    handleDelete,
    navigate,
  } = useDebateActions(setShowDeleteModal);

  useEffect(() => {
    dispatch(listDebates());
  }, [dispatch]);

  const handleJoinDebate = (debate) => {
    if (
      debate.isOpen ||
      debate.participants.some(
        (participant) =>
          participant?._id?.toString() === userInfo?._id?.toString()
      )
    ) {
      navigate(`/active-debate/${debate._id}`);
    } else {
      setSelectedDebateId(debate._id);
      setShowModal(true);
    }
  };

  const handleJoinCodeSubmit = () => {
    const selectedDebate = scheduledDebates.find(
      (debate) => debate._id === selectedDebateId
    );
    if (joinCode === selectedDebate.joinCode) {
      setShowModal(false);
      navigate(`/active-debate/${selectedDebateId}`);
    } else {
      setJoinError("Incorrect join code");
    }
  };

  const handleShowDeleteModal = (debateId) => {
    setSelectedDebateId(debateId);
    setShowDeleteModal(true);
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  // Filter and sort debates
  const today = moment().startOf("day");
  let filteredAndSortedDebates = scheduledDebates
    .filter((debate) => moment(debate.dateTime).isSameOrAfter(today))
    .sort((a, b) => moment(a.dateTime) - moment(b.dateTime));

  if (selectedEvent) {
    filteredAndSortedDebates = filteredAndSortedDebates.filter(
      (debate) => debate.event === selectedEvent
    );
  }

  return (
    <>
      <ToastContainer />
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Scheduled Debates
        </Typography>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel id="event-filter-label">Filter by Event</InputLabel>
          <Select
            labelId="event-filter-label"
            value={selectedEvent}
            onChange={handleEventChange}
            label="Filter by Event"
          >
            <MenuItem value="">All Events</MenuItem>
            <MenuItem value="Lincoln-Douglas">Lincoln-Douglas</MenuItem>
            <MenuItem value="Public Forum">Public Forum</MenuItem>
            <MenuItem value="Policy">Policy</MenuItem>
            {/* <MenuItem value="Extemporaneous">Extemporaneous</MenuItem> */}
          </Select>
        </FormControl>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : filteredAndSortedDebates && filteredAndSortedDebates.length > 0 ? (
          filteredAndSortedDebates.map((debate) => {
            const isCreator =
              debate.creator?.userId?.toString() === userInfo?._id?.toString();
            const isRegistered = debate.participants.some(
              (participant) =>
                participant?.userId?.toString() === userInfo?._id?.toString()
            );
            const isRegisteredAsJudge = debate.judges.some(
              (judge) => judge?.userId?.toString() === userInfo?._id?.toString()
            );
            return (
              <DebateItem
                key={debate._id}
                debate={debate}
                isCreator={isCreator}
                isRegistered={isRegistered}
                isRegisteredAsJudge={isRegisteredAsJudge}
                handleJoinDebate={handleJoinDebate}
                handleUnregister={handleUnregister}
                handleRegister={handleRegister}
                handleRegisterAsJudge={handleRegisterAsJudge}
                handleUnregisterAsJudge={handleUnregisterAsJudge}
                handleShowDeleteModal={handleShowDeleteModal}
              />
            );
          })
        ) : (
          <Alert severity="info">No scheduled debates found.</Alert>
        )}

        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Enter Join Code
            </Typography>
            <TextField
              fullWidth
              label="Join Code"
              variant="outlined"
              margin="normal"
              value={joinCode}
              onChange={(e) => setJoinCode(e.target.value)}
              placeholder="Enter join code"
              error={!!joinError}
              helperText={joinError}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleJoinCodeSubmit}
            >
              Submit
            </Button>
          </Box>
        </Modal>

        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Confirm Cancellation
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Are you sure you want to cancel this debate?
            </Typography>
            <Box
              sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDelete(selectedDebateId)}
              >
                Yes, Cancel
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                No, Go Back
              </Button>
            </Box>
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default ScheduledDebatesScreen;
