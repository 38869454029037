import axios from "axios";

import {
  MESSAGE_DEV_REQUEST,
  MESSAGE_DEV_SUCCESS,
  MESSAGE_DEV_FAIL,
} from "../constants/messageDevConstants";

export const createMessage = (messageData) => async (dispatch) => {
  try {
    dispatch({ type: MESSAGE_DEV_REQUEST });

    const { data } = await axios.post("/api/messageDev/create", messageData);

    dispatch({
      type: MESSAGE_DEV_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MESSAGE_DEV_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
