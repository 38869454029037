import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Snackbar,
} from "@mui/material";
import { X, Download, FileText, Image, Film, Music } from "lucide-react";
import axios from "axios";

const FilePreview = ({ file, onClose, userInfo }) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState("");

  useEffect(() => {
    const fetchPreview = async () => {
      try {
        const response = await axios.get(`/api/files/preview/${file.fileId}`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        const previewBlob = new Blob([response.data], { type: file.fileType });
        const previewUrl = URL.createObjectURL(previewBlob);
        setPreviewUrl(previewUrl);
        setError(null);
      } catch (error) {
        console.error("Error fetching file preview:", error);
        setError("Failed to load preview. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (file.fileId) {
      fetchPreview();
    } else {
      setError("Invalid file data");
      setLoading(false);
    }

    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [file, userInfo.token]);

  const handleDownload = async () => {
    try {
      setDownloadStatus("Downloading...");
      const response = await axios.get(`/api/files/download/${file.fileId}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });

      const blob = new Blob([response.data], { type: file.fileType });

      // Try using the File System Access API
      if ("showSaveFilePicker" in window) {
        try {
          const handle = await window.showSaveFilePicker({
            suggestedName: file.content,
            types: [
              {
                description: "File",
                accept: {
                  [file.fileType]: [`.${file.content.split(".").pop()}`],
                },
              },
            ],
          });
          const writable = await handle.createWritable();
          await writable.write(blob);
          await writable.close();
          setDownloadStatus("Download successful!");
        } catch (err) {
          // User cancelled the save dialog or other error
          console.error("File System Access API error:", err);
          // Fall back to the old method
          fallbackDownload(blob, file.content);
        }
      } else {
        // Fall back to the old method for browsers that don't support File System Access API
        fallbackDownload(blob, file.content);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      setDownloadStatus("Download failed. Please try again.");
    }
  };

  const fallbackDownload = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
    setDownloadStatus("Download initiated. Check your downloads folder.");
  };
  const renderPreview = () => {
    if (loading) {
      return <CircularProgress />;
    }

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    if (!previewUrl) {
      return <Typography>Preview not available for this file type.</Typography>;
    }

    switch (file.fileType.split("/")[0]) {
      case "image":
        return (
          <img
            src={previewUrl}
            alt={file.content}
            style={{ maxWidth: "100%", maxHeight: "70vh" }}
          />
        );
      case "application":
        if (file.fileType === "application/pdf") {
          return (
            <iframe
              src={`${previewUrl}#view=fit`}
              width="100%"
              height="500px"
              title="PDF Preview"
            />
          );
        }
      // For other application types, fall through to default
      case "text":
        return (
          <iframe
            src={previewUrl}
            width="100%"
            height="500px"
            title="File Preview"
          />
        );
      default:
        return (
          <Typography>Preview not available for this file type.</Typography>
        );
    }
  };

  return (
    <>
      <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          File Preview: {file.content}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>{renderPreview()}</DialogContent>
        <DialogActions>
          <Button
            startIcon={<Download />}
            onClick={handleDownload}
            disabled={downloadStatus === "Downloading..."}
          >
            {downloadStatus === "Downloading..."
              ? "Downloading..."
              : "Download"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!downloadStatus}
        autoHideDuration={3000}
        onClose={() => setDownloadStatus("")}
        message={downloadStatus}
      />
    </>
  );
};

export default FilePreview;
