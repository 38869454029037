import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Stack,
  Alert,
  useMediaQuery,
} from "@mui/material";
import Markdown from "markdown-to-jsx";
import { overrides } from "./articleComponents/overrides";
import axios from "axios";

import unitArticlesRegistry from "../static/unitArticlesRegistry";
import { useTheme } from "@emotion/react";

import MarkdownContext from "./MarkdownContext";
import { useDispatch, useSelector } from "react-redux";
import { getArticleStates } from "../redux/actions/articleStatesActions";

// Example blog article data

/**
 * @typedef {{
 *  title: string,
 *  date: string,
 *  route: string
 *  event: string,
 *  unit: string,
 * }} Article
 */

async function fetchArticleByRoute(unit, route) {
  const { data } = await axios.get("/api/articles/" + unit + "/" + route);
  return data;
}

const ArticleOuter = ({ children, isOpen, drawerWidth, ignoreMobile }) => {
  const theme = useTheme();
  const realIsMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = ignoreMobile ? false : realIsMobile;
  return (
    <Box
      sx={{
        width: "100%",
        marginLeft: isMobile
          ? `-${drawerWidth - 20}px`
          : isOpen
          ? 0
          : `-${drawerWidth}px`,
        marginRight: isMobile ? "20px" : 0,
      }}
    >
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        {children}
      </Container>
    </Box>
  );
};

const ArticleInfo = ({ children, isOpen, drawerWidth }) => {
  return (
    <ArticleOuter isOpen={isOpen} drawerWidth={drawerWidth} ignoreMobile>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "calc(100vh - 224px)" }}
      >
        {children}
      </Stack>
    </ArticleOuter>
  );
};

function findNeighborArticleRoute(unit, route, delta) {
  let index = unitArticlesRegistry?.[unit]?.findIndex(
    (article) => article.route === route
  );
  if (index === null) return undefined;
  index += delta;

  let ret = unitArticlesRegistry?.[unit]?.[index]?.route ?? undefined;
  return ret;
}

function allQuestionsComplete(questions) {
  for (let questionVal of Object.values(questions)) {
    if (!questionVal.complete) {
      return false;
    }
  }
  return true;
}

async function updateComplete(complete, userInfo, unit, route, dispatch) {
  if (!userInfo || !userInfo.token) {
    return;
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const articleState = {
    unit,
    route,
    user: userInfo._id,
    state: { complete },
  };

  // Make the API call to create a debate
  await axios.post(
    "/api/article-states/create",
    articleState,
    config // Use the config object here
  );
  dispatch(getArticleStates());
}

const MarkdownWrapper = ({ complete, userInfo, unit, route, article }) => {
  console.log(
    "setting context value tag: ",
    complete ? "complete" : "incomplete"
  );
  const [contextValue, setContextValue] = useState({
    tag: complete ? "complete" : "incomplete",
    questions: {},
  });
  const dispatch = useDispatch();

  let updateContextValue = async (value) => {
    setContextValue(value);
    let complete = allQuestionsComplete(contextValue.questions);
    console.log("allquestionscomplete", JSON.stringify(contextValue.questions));
    if (complete) {
      setContextValue((contextValue) => {
        return {
          ...contextValue,
          tag: "complete",
        };
      });
      updateComplete(true, userInfo, unit, route, dispatch);
    }
    if (!complete) {
      setContextValue((contextValue) => {
        return {
          ...contextValue,
          tag: "incomplete",
        };
      });
      updateComplete(false, userInfo, unit, route, dispatch);
    }
  };

  return (
    <MarkdownContext.Provider
      value={{
        contextValue,
        updateContextValue,
        setInitContextValue: setContextValue,
      }}
    >
      <Markdown
        options={{
          wrapper: "article",
          forceBlock: true,
          overrides,
        }}
      >
        {article.content}
      </Markdown>
    </MarkdownContext.Provider>
  );
};

const Article =
  /**
   * @param {{article: Article}} props
   * @returns
   */
  ({ route, unit, drawerWidth, isOpen }) => {
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const articleStates = useSelector((state) => state.articleStates?.data);
    const articleStatesTag = useSelector((state) => state.articleStates?.type);
    const { userInfo } = useSelector((state) => state.auth);

    let nextArticleRoute = findNeighborArticleRoute(unit, route, 1);
    let prevArticleRoute = findNeighborArticleRoute(unit, route, -1);

    useEffect(() => {
      const loadArticle = async () => {
        try {
          if (route === undefined) {
            console.log("it was set");
            setArticle(null);
          } else {
            const fetchedArticle = await fetchArticleByRoute(unit, route);
            setArticle(fetchedArticle);
          }
        } catch (err) {
          setError("Failed to load article");
        } finally {
          setLoading(false);
        }
      };

      loadArticle();
    }, [unit, route]);

    if (
      loading ||
      (articleStates === undefined && articleStatesTag !== "failure")
    ) {
      return (
        <ArticleInfo isOpen={isOpen} drawerWidth={drawerWidth}>
          Loading...
        </ArticleInfo>
      );
    }

    if (error) {
      return (
        <ArticleInfo isOpen={isOpen} drawerWidth={drawerWidth}>
          <Alert severity={"error"}>{error}</Alert>
        </ArticleInfo>
      );
    }
    if (article == null) {
      return (
        <ArticleInfo isOpen={isOpen} drawerWidth={drawerWidth}>
          No article selected
        </ArticleInfo>
      );
    }

    return (
      <ArticleOuter isOpen={isOpen} drawerWidth={drawerWidth}>
        <Typography variant="h3" component="h1" gutterBottom>
          {article.title}
        </Typography>

        <MarkdownWrapper
          complete={articleStates?.[unit]?.[route]?.complete}
          userInfo={userInfo}
          unit={unit}
          route={route}
          article={article}
        ></MarkdownWrapper>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button
            variant="outlined"
            color="primary"
            href={"/learn/" + unit + "/" + prevArticleRoute}
            disabled={prevArticleRoute === undefined}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            color="primary"
            href={"/learn/" + unit + "/" + nextArticleRoute}
            disabled={nextArticleRoute === undefined}
          >
            Next
          </Button>
        </Stack>
      </ArticleOuter>
    );
  };

export default Article;
