import {
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  UPDATE_PARTICIPANTS,
  DEBATE_STARTED,
  UPDATE_ROLES,
  UPDATE_CURRENT_SPEECH_INDEX,
  START_PREP_TIME,
  END_PREP_TIME,
} from "../constants/socketConstants";

const initialState = {
  socket: null,
  participants: [],
  judges: [],
  isReady: [],
  roles: {},
  debateStarted: false,
  currentSpeechIndex: 0,
  prepTimeActive: false, // Add prepTimeActive to the initial state
  debateEnded: false,
};

export const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOCKET_CONNECT:
      return {
        ...state,
        socket: action.payload,
      };
    case SOCKET_DISCONNECT:
      return {
        ...state,
        socket: null,
        participants: [],
        judges: [],
        isReady: [],
        roles: {},
        debateStarted: false,
        currentSpeechIndex: 0,
        prepTimeActive: false,
      };
    case UPDATE_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload.participants,
        judges: action.payload.judges,
      };
    case UPDATE_ROLES:
      return { ...state, roles: action.payload };
    case DEBATE_STARTED:
      return {
        ...state,
        debateStarted: true,
      };
    case UPDATE_CURRENT_SPEECH_INDEX:
      return {
        ...state,
        currentSpeechIndex: action.payload,
      };
    case START_PREP_TIME:
      return {
        ...state,
        prepTimeActive: true,
      };
    case END_PREP_TIME:
      return {
        ...state,
        prepTimeActive: false,
      };
    case "DEBATE_COMPLETED":
      return {
        ...state,
        debateEnded: true,
      };
    default:
      return state;
  }
};
