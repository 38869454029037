import Question from './Question';
import Test from './Test';

export const overrides = {
  Test: {
    component: Test,
  },
  Question: {
    component: Question,
  },
};
