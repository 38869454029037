import { socket } from "../../context/socket";
import {
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  UPDATE_PARTICIPANTS,
  DEBATE_STARTED,
  UPDATE_ROLES,
  UPDATE_CURRENT_SPEECH_INDEX,
  SIGNAL_RECEIVED,
} from "../constants/socketConstants";
import {
  COIN_FLIP_RESULT,
  SIDE_ORDER_SELECTED,
  SET_STAGE,
} from "../constants/coinFlipConstants";
import { updateCurrentSpeechIndex } from "../actions/socketActions";

const socketMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case SOCKET_CONNECT:
        if (!socket.connected) {
          console.log("Connecting to WebSocket");
          socket.connect();

          socket.on("connect", () => {
            console.log("WebSocket connected");
            const { debateId, userInfo } = action.payload;
            socket.emit("join", {
              debateId,
              userId: userInfo._id,
              userName: userInfo.name,
            });
          });

          // socket.on("disconnect", () => {
          //   socket.emit("leave", {
          //     debateId: action.payload.debateId,
          //     userId: action.payload.userInfo._id,
          //     userName: action.payload.userInfo.name,
          //   });
          //   console.log("WebSocket disconnected");
          // });

          socket.on("updateParticipants", (data) => {
            dispatch({ type: UPDATE_PARTICIPANTS, payload: data });
          });

          socket.on("coinFlipResult", (data) => {
            console.log("Received coinFlipResult event", data);
            dispatch({ type: COIN_FLIP_RESULT, payload: data });
            dispatch({ type: SET_STAGE, payload: 1 });
          });

          socket.on("sideOrderSelected", (data) => {
            console.log("Received sideOrderSelected event", data);
            dispatch({ type: SIDE_ORDER_SELECTED, payload: data });
            dispatch({ type: SET_STAGE, payload: 2 });
          });

          socket.on("rolesFinalized", (data) => {
            console.log("Received rolesFinalized event", data);
            dispatch({ type: UPDATE_ROLES, payload: data.roles });
            if (data.event === "Public Forum") {
              dispatch({ type: SET_STAGE, payload: 3 });
            }
          });

          socket.on("updateCurrentSpeechIndex", (data) => {
            console.log("Received updateCurrentSpeechIndex event", data);
            dispatch(updateCurrentSpeechIndex(data));
          });

          socket.on("debateStarted", () => {
            dispatch({ type: DEBATE_STARTED });
          });

          socket.on("userJoined", (data) => {
            console.log("New user joined", data);
            // Dispatch an action to handle new user, if needed
          });

          socket.on("userLeft", (data) => {
            console.log("User left", data);
            // Dispatch an action to handle user leaving, if needed
          });

          socket.on("signal", (data) => {
            console.log("Received signal in middleware", data);
            dispatch({ type: SIGNAL_RECEIVED, payload: data });
          });

          socket.on("error", (error) => {
            console.error("WebSocket error:", error);
            // Dispatch an action to handle the error, if needed
          });
        }
        break;

      case SOCKET_DISCONNECT:
        if (socket.connected) {
          socket.emit("leave", {});
          socket.disconnect();
        }
        break;

      case "SEND_SIGNAL":
        {
          const { to, from, signal } = action.payload;
          socket.emit("signal", {
            to,
            from,
            signal,
          });
        }
        break;

      default:
        break;
    }

    return next(action);
  };

export default socketMiddleware;
