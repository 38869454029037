import { Row, Col, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";

const JudgesList = () => {
  const judges = useSelector((state) => state.socket.judges);

  if (!judges) {
    return null;
  }

  const inLobbyJudges = judges.filter((judge) => judge.isInLobby);
  const waitingJudges = judges.filter((judge) => !judge.isInLobby);

  return (
    judges.length > 0 && (
      <>
        <h2>Judges</h2>
        <Row>
          <Col md={6}>
            <h4>In Lobby</h4>
            <ListGroup>
              {inLobbyJudges.map((judge) => (
                <ListGroup.Item key={judge.userId}>
                  {judge.name} {judge.isReady && "✔️"}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={6}>
            <h4>Waiting for</h4>
            <ListGroup>
              {waitingJudges.map((judge) => (
                <ListGroup.Item key={judge.userId}>
                  {judge.name} {judge.isReady && "✔️"}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>{" "}
      </>
    )
  );
};

export default JudgesList;
