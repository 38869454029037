import {
  CREATE_DEBATE_REQUEST,
  CREATE_DEBATE_SUCCESS,
  CREATE_DEBATE_FAIL,
  FETCH_DEBATE_REQUEST,
  FETCH_DEBATE_SUCCESS,
  FETCH_DEBATE_FAIL,
  DEBATE_LIST_REQUEST,
  DEBATE_LIST_SUCCESS,
  DEBATE_LIST_FAIL,
  DEBATE_REGISTER_SUCCESS,
  DEBATE_REGISTER_FAIL,
  DEBATE_UNREGISTER_SUCCESS,
  DEBATE_UNREGISTER_FAIL,
  DEBATE_DELETE_SUCCESS,
  DEBATE_DELETE_FAIL,
  REGISTER_JUDGE_SUCCESS,
  REGISTER_JUDGE_FAIL,
  UNREGISTER_JUDGE_SUCCESS,
  UNREGISTER_JUDGE_FAIL,
  ASSIGN_ROLE_SUCCESS,
  ASSIGN_ROLE_FAIL,
  UNASSIGN_ROLE_SUCCESS,
  UNASSIGN_ROLE_FAIL,
  READY_ROLE_SUCCESS,
  READY_ROLE_FAIL,
  UNREADY_ROLE_SUCCESS,
  UNREADY_ROLE_FAIL,
  VOTE_DEBATE_FAIL,
  VOTE_DEBATE_REQUEST,
  VOTE_DEBATE_SUCCESS,
  COMPLETE_DEBATE_FAIL,
  COMPLETE_DEBATE_REQUEST,
  COMPLETE_DEBATE_SUCCESS,
  ADD_TRANSCRIPT_REQUEST,
  ADD_TRANSCRIPT_SUCCESS,
  ADD_TRANSCRIPT_FAIL,
} from "../constants/debateConstants.js";

const initialState = {
  scheduledDebates: [],
  activeDebate: null,
  availableDebates: [],
  selectedDebate: null,
  debateError: null,
  isLoading: false,
  error: null,
};

const debateReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DEBATE_REQUEST:
      return { ...state, isLoading: true };
    case CREATE_DEBATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scheduledDebates: [...state.scheduledDebates, action.payload],
      };
    case CREATE_DEBATE_FAIL:
      return { ...state, isLoading: false, debateError: action.payload };
    case FETCH_DEBATE_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_DEBATE_SUCCESS:
      return { ...state, isLoading: false, selectedDebate: action.payload };
    case FETCH_DEBATE_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case DEBATE_LIST_REQUEST:
      return { isLoading: true, scheduledDebates: [] }; // Initialize as empty array
    case DEBATE_LIST_SUCCESS:
      return { isLoading: false, scheduledDebates: action.payload };
    case DEBATE_LIST_FAIL:
      return { isLoading: false, error: action.payload, scheduledDebates: [] };
    case DEBATE_REGISTER_SUCCESS:
    case DEBATE_UNREGISTER_SUCCESS:
    case REGISTER_JUDGE_SUCCESS:
      return { ...state, selectedDebate: action.payload };
    case UNREGISTER_JUDGE_SUCCESS:
      return {
        ...state,
        scheduledDebates: state.scheduledDebates.map((debate) =>
          debate._id === action.payload._id ? action.payload : debate
        ),
      };
    case DEBATE_REGISTER_FAIL:
    case DEBATE_UNREGISTER_FAIL:
    case REGISTER_JUDGE_FAIL:
    case UNREGISTER_JUDGE_FAIL:
      return { ...state, error: action.payload };
    case VOTE_DEBATE_REQUEST:
      return { ...state, isLoading: true };
    case VOTE_DEBATE_SUCCESS:
      return { ...state, isLoading: false, selectedDebate: action.payload };
    case VOTE_DEBATE_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case COMPLETE_DEBATE_REQUEST:
      return { ...state };
    case COMPLETE_DEBATE_SUCCESS:
      return { ...state, selectedDebate: action.payload };
    case COMPLETE_DEBATE_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case DEBATE_DELETE_FAIL:
      return { ...state, error: action.payload };
    case DEBATE_DELETE_SUCCESS:
      return {
        ...state,
        scheduledDebates: state.scheduledDebates.filter(
          (debate) => debate._id !== action.payload
        ),
      };
    case ASSIGN_ROLE_SUCCESS:
    case UNASSIGN_ROLE_SUCCESS:
    case READY_ROLE_SUCCESS:
    case UNREADY_ROLE_SUCCESS:
      return {
        ...state,
        scheduledDebates: state.scheduledDebates.map((debate) =>
          debate._id === action.payload._id ? action.payload : debate
        ),
      };
    case ASSIGN_ROLE_FAIL:
    case UNASSIGN_ROLE_FAIL:
    case READY_ROLE_FAIL:
    case UNREADY_ROLE_FAIL:
      return { ...state, error: action.payload };
    case "RESET_SELECTED_DEBATE":
      return {
        ...state,
        selectedDebate: null,
      };
    case ADD_TRANSCRIPT_REQUEST:
      return { ...state };
    case ADD_TRANSCRIPT_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        selectedDebate: {
          ...state.selectedDebate,
          allTranscriptsLoaded: action.payload.isLastTranscript,
          transcripts: [
            ...(state.selectedDebate.transcripts || []),
            action.payload.data,
          ],
        },
      };
    case ADD_TRANSCRIPT_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default debateReducer;
