import {
  GET_ARTICLE_STATES_FAIL,
  GET_ARTICLE_STATES_REQUEST,
  GET_ARTICLE_STATES_SUCCESS,
} from "../constants/articleStatesConstants";

const initialState = {
  tag: "empty",
};

export const articleStatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLE_STATES_REQUEST:
      return { ...state, tag: "loading" };
    case GET_ARTICLE_STATES_SUCCESS:
      if (action.payload === "logout") {
        return { type: "failure", reason: "logout" };
      }
      let data = {};
      for (let item of action.payload) {
        if (!data[item.unit]) {
          data[item.unit] = {};
        }
        data[item.unit][item.route] = item.state;
      }
      return {
        tag: "success",
        data,
      };
    case GET_ARTICLE_STATES_FAIL:
      return { type: "failure" };
    default:
      return state;
  }
};
