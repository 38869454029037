// SocketLayout.js
import React from "react";
import { SocketContext, socket } from "./context/socket";

const SocketLayout = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default SocketLayout;
