import React from "react";
import { Button } from "react-bootstrap";

const TimerControls = ({
  timerRunning,
  onTimerAction,
  onEndSpeech,
  canEndSpeech,
}) => {
  return (
    <div>
      <Button onClick={onTimerAction}>
        {timerRunning ? "Pause Speech" : "Start Speech"}
      </Button>
      <Button onClick={onEndSpeech} disabled={!canEndSpeech}>
        End Speech
      </Button>
    </div>
  );
};

export default TimerControls;
