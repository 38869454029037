import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Article from "../components/Article";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ArticleDrawer from "../components/ArticleDrawer";
import { useTheme } from "@emotion/react";
import { getArticleStates } from "../redux/actions/articleStatesActions";

const ArticleScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getArticleStates());
  }, [dispatch]);

  const { articleUnit, articleRoute } = useParams();
  const drawerWidth = 250;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isOpen, setIsOpen] = useState(articleRoute == null || !isMobile);
  return (
    <Box sx={{ display: "flex" }}>
      <ArticleDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        drawerWidth={drawerWidth}
        unit={articleUnit}
        route={articleRoute}
      ></ArticleDrawer>
      <Article
        route={articleRoute}
        unit={articleUnit}
        drawerWidth={drawerWidth}
        isOpen={isOpen}
      ></Article>
    </Box>
  );

  // return <Article article={article}>{article}</Article>;
  // return <p>{article}</p>;
};

export default ArticleScreen;
