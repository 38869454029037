import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Paper,
} from "@mui/material";
import { Container, Spinner, Alert, Button } from "react-bootstrap";

const TranscriptViewer = ({ phases }) => {
  console.log(phases);
  const [selectedSpeech, setSelectedSpeech] = useState("");
  const [speechOptions, setSpeechOptions] = useState([]);
  const { selectedDebate, isLoading, error } = useSelector(
    (state) => state.debates
  );

  useEffect(() => {
    console.log(selectedDebate);
    if (selectedDebate && selectedDebate.transcripts) {
      const options = selectedDebate.transcripts.map((transcript, index) => ({
        value: index,
        label: `${phases[transcript.speechIndex].name} - ${transcript.speaker}`,
      }));
      setSpeechOptions(options);
    }
  }, [selectedDebate]);

  const handleSpeechChange = (event) => {
    setSelectedSpeech(event.target.value);
  };

  const getSelectedTranscript = () => {
    if (selectedSpeech === "") return null;
    return selectedDebate.transcripts[selectedSpeech];
  };

  if (isLoading) return <Spinner animation="border" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
      <FormControl fullWidth>
        <InputLabel id="speech-select-label">Select Speech</InputLabel>
        <Select
          labelId="speech-select-label"
          id="speech-select"
          value={selectedSpeech}
          label="Select Speech"
          onChange={handleSpeechChange}
        >
          {speechOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {getSelectedTranscript() && (
        <div style={{ marginTop: "20px" }}>
          <Typography variant="h6">Transcript:</Typography>
          <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
            {getSelectedTranscript().content}
          </Typography>
        </div>
      )}
    </Paper>
  );
};

export default TranscriptViewer;
