import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Row, Col, Alert } from "react-bootstrap";
import { SocketContext } from "../context/socket";

const RoleAssignment = ({ debateId, userId, userName, event }) => {
  const socket = useContext(SocketContext);
  //console.log("RoleAssignment socket", socket);
  const participants = useSelector((state) => state.socket.participants);
  const { userInfo } = useSelector((state) => state.auth);
  const [roles, setRoles] = useState({
    teamA1: null,
    teamA2: null,
    teamB1: null,
    teamB2: null,
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (participants) {
      const newRoles = {};
      participants.forEach((participant) => {
        if (participant.role) {
          newRoles[participant.role] = participant;
        }
      });
      setRoles(newRoles);
    }
  }, [participants]);

  const handleRoleUpdate = (role) => {
    const isReady = participants.find(
      (participant) => participant.userId === userInfo._id
    )?.isReady;
    if (isReady) {
      setErrorMessage("You cannot change roles while set to ready");
      return;
    }
    // Prevent emit if role is unchanged
    const currentRole = Object.keys(roles).find(
      (key) => roles[key]?.userId === userId
    );
    if (role && currentRole !== role) {
      socket.emit("assignRole", { debateId, role, userId, userName });
      setErrorMessage(""); // Clear error message upon role selection
    } else if (role && currentRole === role) {
      socket.emit("unassignRole", { debateId, role });
    }
  };

  return (
    <div>
      <h4>Select your Team and Speaking Order</h4>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Row>
        <Col>Team A</Col>
        <Col>Team B</Col>
      </Row>
      <Row>
        <Col>
          <Button
            onClick={() => handleRoleUpdate("teamA1")}
            disabled={roles.teamA1 && roles.teamA1.userId !== userId}
          >
            {roles.teamA1 ? `Speaker 1 - ${roles.teamA1.name}` : "Speaker 1"}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => handleRoleUpdate("teamB1")}
            disabled={roles.teamB1 && roles.teamB1.userId !== userId}
          >
            {roles.teamB1 ? `Speaker 1 - ${roles.teamB1.name}` : "Speaker 1"}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            onClick={() => handleRoleUpdate("teamA2")}
            disabled={roles.teamA2 && roles.teamA2.userId !== userId}
          >
            {roles.teamA2 ? `Speaker 2 - ${roles.teamA2.name}` : "Speaker 2"}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => handleRoleUpdate("teamB2")}
            disabled={roles.teamB2 && roles.teamB2.userId !== userId}
          >
            {roles.teamB2 ? `Speaker 2 - ${roles.teamB2.name}` : "Speaker 2"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default RoleAssignment;
