import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { voteForDebate } from "../redux/actions/debateActions";
import { useDispatch, useSelector } from "react-redux";

const Vote = ({ debateId }) => {
  const { selectedDebate, isLoading, error } = useSelector(
    (state) => state.debates
  );
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [selectedWinner, setSelectedWinner] = useState("");
  const [rfd, setRfd] = useState("");

  const handleWinnerChange = (event) => {
    setSelectedWinner(event.target.value);
  };

  const handleRfdChange = (event) => {
    setRfd(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedWinner && rfd) {
      dispatch(voteForDebate(debateId, selectedWinner, rfd));
    }
  };

  return (
    <Box
      sx={{ maxWidth: 500, margin: "auto", padding: 2, textAlign: "center" }}
    >
      {selectedDebate.rfd.some(
        (rfdEntry) => rfdEntry.judgeId === userInfo._id
      ) ? (
        <Typography variant="h6" gutterBottom>
          You voted {selectedWinner}. Thanks for judging!
        </Typography>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Vote for the Winner
          </Typography>
          <FormControl component="fieldset">
            <FormLabel component="legend">Select Winner</FormLabel>
            <RadioGroup
              row
              aria-label="winner"
              name="winner"
              value={selectedWinner}
              onChange={handleWinnerChange}
            >
              <FormControlLabel
                value="Aff"
                control={<Radio />}
                label="Affirmative"
              />
              <FormControlLabel
                value="Neg"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            label="Reason for Decision"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            margin="normal"
            value={rfd}
            onChange={handleRfdChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedWinner || !rfd}
          >
            Submit
          </Button>
        </>
      )}
    </Box>
  );
};

export default Vote;
