import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
} from "@mui/material";

const DebateItem = ({
  debate,
  isCreator,
  isRegistered,
  isRegisteredAsJudge,
  handleJoinDebate,
  handleUnregister,
  handleRegister,
  handleRegisterAsJudge,
  handleUnregisterAsJudge,
  handleShowDeleteModal,
  handleViewPastDebate,
  isPastDebate,
}) => {
  const maxDebaters =
    debate.event === "Public Forum" || debate.event === "Policy" ? 4 : 2;
  const registeredDebaters = debate.participants.length;

  return (
    <Card variant="outlined" sx={{ mb: 3 }}>
      <CardContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              {debate.resolution}
            </Typography>
            <Typography variant="subtitle1">Event: {debate.event}</Typography>
            <Typography variant="body2" color="textSecondary">
              Date and Time:{" "}
              {new Date(debate.dateTime).toLocaleString(undefined, {
                timeZoneName: "short",
              })}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Host: {debate.creator.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Host Team Side: {debate.side}
            </Typography>
            {debate.event === "Public Forum" && debate.side !== "Coinflip" && (
              <Typography variant="body2" color="textSecondary">
                Host Team Speaking: {debate.speakerOrder}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Description: {debate.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Access: {debate.isOpen ? "Public" : "Private"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Registered Debaters: {registeredDebaters}/{maxDebaters}
                </Typography>
              </Box>
              <Box mt={2}>
                {isPastDebate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleViewPastDebate(debate._id)}
                  >
                    View Debate
                  </Button>
                ) : isCreator ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleJoinDebate(debate)}
                      >
                        Join Debate
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleShowDeleteModal(debate._id)}
                      >
                        Cancel Debate
                      </Button>
                    </Grid>
                  </Grid>
                ) : isRegistered ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleJoinDebate(debate)}
                      >
                        Join Debate
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleUnregister(debate._id)}
                      >
                        Unregister as Debater
                      </Button>
                    </Grid>
                  </Grid>
                ) : isRegisteredAsJudge ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleJoinDebate(debate)}
                      >
                        Join Debate
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleUnregisterAsJudge(debate._id)}
                      >
                        Unregister as Judge
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRegister(debate._id)}
                        disabled={registeredDebaters >= maxDebaters}
                      >
                        {registeredDebaters >= maxDebaters
                          ? "FULL"
                          : "Register As Debater"}
                      </Button>
                    </Grid>
                    {debate.allowJudges && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleRegisterAsJudge(debate._id)}
                        >
                          Register as Judge
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {!debate.isOpen && isCreator && (
          <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
            Join Code: {debate.joinCode}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DebateItem;
