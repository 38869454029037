import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

const AudioControls = ({ localStream, isMuted, onMuteChange }) => {
  const toggleMute = useCallback(() => {
    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      if (audioTrack) {
        const newMuteState = !isMuted;
        audioTrack.enabled = !newMuteState;
        onMuteChange(newMuteState);
      }
    }
  }, [localStream, isMuted, onMuteChange]);

  useEffect(() => {
    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !isMuted;
      }
    }
  }, [localStream, isMuted]);

  return (
    <Button
      variant="contained"
      color={isMuted ? "secondary" : "primary"}
      onClick={toggleMute}
      startIcon={isMuted ? <MicOffIcon /> : <MicIcon />}
    >
      {isMuted ? "Unmute" : "Mute"}
    </Button>
  );
};

export default AudioControls;
